import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Modal } from '@tabeo/scarf'
import { Button, Notification } from '@tabeo/scarf2'
import { useRef } from 'react'
import { useVendor } from 'resources/Vendor'
import UpdateDetailsModal from './UpdateDetailsModal'

export default function StripeOnboarding({
  className,
}: {
  className?: string
}) {
  const { data } = useVendor()
  const modalRef = useRef<Modal>()

  if (
    !data?.connectedAccount?.status ||
    data?.connectedAccount?.status === 'completed'
  ) {
    return null
  }

  return (
    <>
      <UpdateDetailsModal ref={modalRef} />
      <Notification className={className}>
        <InformationCircleIcon className="text-tabeo-blue-3" />
        <div>
          <span className="font-medium">
            Action needed: Verify your business details.
          </span>{' '}
          Our payments provider, Stripe, needs some information to verify your
          business. Please update your details now to ensure uninterrupted
          payments.
        </div>
        <Button
          className="w-fit text-tabeo-ink-1 focus:border-tabeo-ink-1"
          size="sm"
          variant="secondary"
          onClick={() => {
            modalRef.current?.open()
          }}
        >
          Update details
        </Button>
      </Notification>
    </>
  )
}
