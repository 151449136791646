import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormError,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PasswordCriterias,
  PasswordInput,
} from '@tabeo/scarf2'
import { AuthLayout, AuthLayoutBox } from 'components/layouts/AuthLayout'
import z from 'lib/zod'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

const formSchema = z
  .object({
    password: z.string().superRefine((val, ctx) => {
      // Min 8 char long
      if (val.length < 8) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Password must be at least 8 characters long',
        })
      }

      // Contains uppercase
      if (!/[A-Z]/.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Password must contain at least one uppercase letter',
        })
      }

      // Contains lowercase
      if (!/[a-z]/.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Password must contain at least one lowercase letter',
        })
      }

      // Contains number
      if (!/\d/.test(val)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Password must contain at least one number',
        })
      }
    }),
    confirmPassword: z.string().refine(val => val.length > 0, {
      message: 'This field is required',
    }),
  })
  .refine(data => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
  })

type FormValues = z.infer<typeof formSchema>

export default function SetNewPassword() {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })
  const { resource } = useAuth()
  const { passwordToken } = useParams<{ passwordToken: string }>()

  async function onSubmit(values: FormValues) {
    try {
      await resource?.resetPassword({
        ...values,
        passwordToken,
      })
    } catch {
      form.setError('root', {
        message:
          'The password reset link is invalid or has expired. Please request a new link.',
      })
    }
  }

  return (
    <AuthLayout>
      <AuthLayoutBox>
        {form.formState.isSubmitSuccessful ? (
          <>
            <h1 className="title mb-2">Password updated</h1>
            <p className="mb-6 text-tabeo-ink-1">
              You’ve successfully reset your password. Please sign in using your
              new password.
            </p>
            <Link to="/auth/password-login" className="anchor">
              <Button className="w-full" variant="primary">
                Sign in
              </Button>
            </Link>
          </>
        ) : (
          <>
            <h1 className="title mb-6">Set a new password</h1>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-5"
              >
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>New password</FormLabel>
                      <FormControl>
                        <PasswordInput {...field} />
                      </FormControl>
                      <PasswordCriterias
                        value={field.value}
                        shouldValidate={!!form.formState.errors.password}
                      />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Repeat new password</FormLabel>
                      <FormControl>
                        <PasswordInput {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="submit"
                  variant="primary"
                  loading={form.formState.isSubmitting}
                  className="w-full"
                >
                  Confirm new password
                </Button>
                <FormError />
              </form>
            </Form>
          </>
        )}
      </AuthLayoutBox>
    </AuthLayout>
  )
}
