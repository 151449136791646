import * as Sentry from '@sentry/react'
import config from 'config'
import { createBrowserHistory } from 'history'
import { Route } from 'react-router-dom'

// Create Custom Sentry Route component
export const SentryRoute = Sentry.withSentryRouting(Route)

export const browserHistory = createBrowserHistory()

Sentry.init({
  dsn: 'https://2b00c6c3e6174f74979639805efd5e61@o109864.ingest.us.sentry.io/241765',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history: browserHistory }),
  ],
  environment: config.ENV,
  release: process.env.REACT_APP_GIT_SHA,
  // We recommend adjusting this value in production, or using tracesSampler
  tracesSampleRate: 0.01,
  // Ignore errors from external scripts not hosted on our domains or anything that includes stripe
  // allowUrls: [/^https?:\/\/([a-z0-9-]+\.)*tabeo\.[a-z.]+/, /localhost/],
  // Filter out unwanted errors
  beforeSend(event, hint) {
    // Cast error to a more specific type to avoid type errors
    const error = hint.originalException as
      | (Error & {
          isAxiosError: never
        })
      | (Error & {
          isAxiosError: true
          response: { status: number }
        })
    // Extract error message
    const message = (error.message || '').toLowerCase()

    // Filter out common error messages (case-insensitive)
    const ignoredMessages = [
      'network error',
      'networkerror', // Firefox
      'failed to fetch',
      'load failed',
      'failed to load stripe',
      'request aborted',
    ]
    if (ignoredMessages.some(ignored => message.includes(ignored))) {
      return null
    }

    // Ignore 401 errors from api responses
    if (error?.isAxiosError) {
      if (error.response.status === 401) {
        return null
      }
    }

    // For all other events, send to Sentry
    return event
  },
})
