import {
  Button,
  CurrencyInput,
  Flex,
  Heading,
  Modal,
  Prompt,
  View,
} from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import { format } from '@tabeo/sharpei/utils/currency'
import formError from '@tabeo/sharpei/utils/formError'
import { required, valueRange } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import Connecting from 'components/terminal/Connecting'
import RegisterReader from 'components/terminal/RegisterReader'
import TerminalPaymentModal from 'components/terminal/TerminalPayment/Modal'
import config from 'config'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { CommonReader } from 'pages/NNTS/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'
import { useCardReaders } from 'resources/CardReaders'
import { useMerchant } from 'resources/Merchant'

const { CURRENCY } = config

const defaultInitialValues = {
  currency: CURRENCY,
}

export type TerminalPaymentFormProps = {
  initialValues?: any
  onSubmit: (values: any) => any
  onFormChange: (change: any) => void
} & React.ComponentProps<typeof View>

function TerminalPaymentForm({
  onFormChange,
  onSubmit,
  initialValues,
  ...rest
}: TerminalPaymentFormProps) {
  const { t } = useTranslation()
  const { connect, disconnect, connectionStatus, sdk } = useStripeTerminal()
  const { data: merchant } = useMerchant()
  const isServerDrivenFlow =
    merchant?.flags?.is_server_driven_reader_connection_capable
  const [isConnectionTested, setIsConnectionTested] =
    useState(isServerDrivenFlow)

  const modal = useRef<Modal>()

  const testConnection = useCallback(async () => {
    try {
      await connect({ retry: true })
    } catch {
      // Do nothing
    } finally {
      // connection works even the reader is in use and the call is failed
      setIsConnectionTested(true)
    }
  }, [connect])

  useEffect(() => {
    if (!isServerDrivenFlow) {
      testConnection()
    }
    return () => {
      if (!isServerDrivenFlow) {
        disconnect(30_000)
      }
    }
  }, [testConnection, disconnect, isServerDrivenFlow])

  const { data: cardReaders } = useCardReaders()

  const handleSubmit = async (values: any) => {
    try {
      let reader: CommonReader | undefined | null

      if (isServerDrivenFlow) {
        reader = cardReaders?.items?.[0]
      } else {
        reader = sdk?.getConnectedReader()
      }
      if (!reader) {
        throw new Error('Reader not found')
      }

      const payload = {
        amount: values.price,
        currency: values.currency,
        readerID: reader.id,
      }

      const terminalPayment = await onSubmit(payload, isServerDrivenFlow)

      // Add terminalPayment.sagaUUID to URL search params if isServerDrivenFlow
      if (isServerDrivenFlow) {
        const url = new URL(window.location.href)
        url.searchParams.set('sagaUUID', terminalPayment.sagaUUID)
        window.history.pushState({}, '', url.toString())
      }

      modal.current?.open({
        id: terminalPayment.id,
        reader,
      })
    } catch (e) {
      return formError(e)
    }
  }

  const priceValidator = valueRange(
    1_00,
    10_000_00,
    t(`Please specify an amount between {{min}} and {{max}}`, {
      min: format(1_00),
      max: format(10_000_00),
    })
  )

  // When intialValues are not memoized, the form is reinitialized on every render
  // and this causes an infinite loop for some reason. This is a workaround while NNTS is not ready.
  const memoizedInitialValues = useMemo(
    () => ({
      ...initialValues,
      ...defaultInitialValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(initialValues)]
  )

  const shouldRegisterReader = cardReaders?.items?.length === 0

  // Handle server driven flows
  useEffect(() => {
    async function resumeServerDrivenFlow() {
      const url = new URL(window.location.href)
      const sagaUUID = url.searchParams.get('sagaUUID')
      if (sagaUUID) {
        const { saga } = await api.get(`/sagas/${sagaUUID}`)
        modal.current?.open({
          id: saga.projections.terminalPayment.id,
          reader: {
            id: saga.projections.reader.id,
          },
        })
      }
    }
    resumeServerDrivenFlow()
  }, [])

  return (
    <View bg="white" borderRadius="0 0 4px 4px" {...rest}>
      {shouldRegisterReader ? (
        <RegisterReader />
      ) : (
        <>
          {!(isConnectionTested || connectionStatus === 'connected') && (
            <Flex
              p={[5, 10]}
              alignItems="center"
              minHeight="480px"
              justifyContent="center"
            >
              <Connecting titleProps={{ fontSize: '22px' }} />
            </Flex>
          )}
          {(isConnectionTested || connectionStatus === 'connected') && (
            <>
              <TerminalPaymentModal ref={modal} />
              <Form
                onSubmit={handleSubmit}
                initialValues={memoizedInitialValues}
                mutators={{
                  setFieldValue: (args, state, tools) => {
                    const [field, value] = args
                    tools.changeValue(state, field, () => value)
                  },
                }}
                destroyOnUnregister
              >
                {({
                  handleSubmit,
                  form: { reset, resetFieldState, getRegisteredFields },
                  submitting,
                  valid,
                  validating,
                }) => {
                  return (
                    <form
                      onSubmit={async e => {
                        const error = await handleSubmit(e)
                        if (!error && valid) {
                          reset()

                          // https://github.com/final-form/final-form/issues/317
                          // reset() doesn't clean some metadata
                          getRegisteredFields().forEach(field => {
                            // Handle conditional fields
                            if (getRegisteredFields().includes(field)) {
                              resetFieldState(field)
                            }
                          })
                        }
                      }}
                    >
                      <FormSpy
                        onChange={change => {
                          onFormChange({
                            ...change,
                          })
                        }}
                      />
                      <View p={[2, 7]} pb={[2, 6]}>
                        <Heading fontWeight="semibold" mb={5}>
                          {t('Transaction')}
                        </Heading>

                        <Field
                          name="price"
                          clearable
                          maxWidth="100%"
                          label={
                            <Prompt mb={1} fontWeight="semibold">
                              <Trans>
                                Price{' '}
                                <Prompt display="inline" color="ink.2">
                                  (including VAT)
                                </Prompt>
                              </Trans>
                            </Prompt>
                          }
                          // @ts-ignore
                          component={CurrencyInput}
                          validate={[required, priceValidator]}
                        />
                        <Button
                          width="100%"
                          loading={submitting}
                          disabled={validating}
                        >
                          {t('Send to reader')}
                        </Button>
                        <FormError />
                      </View>
                    </form>
                  )
                }}
              </Form>
            </>
          )}
        </>
      )}
    </View>
  )
}

export default TerminalPaymentForm
