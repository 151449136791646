import * as React from 'react'

function BgBlob(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={266}
      height={200}
      viewBox="0 0 266 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.129 10.888c48.268-17.247 102.829-15.654 133.37 18.63 31.2 35.024 27.703 87.589-3.075 127.634-28.666 37.298-79.608 48.212-125.995 40.298C60.871 188.312 1.911 166.66.749 116.261-.429 65.139 56.344 30.108 110.13 10.888z"
        fill="url(#paint0_linear_1398_4040)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1398_4040"
          x1={0.731445}
          y1={0.121033}
          x2={253.209}
          y2={214.107}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset={1} stopColor="#F9FAFB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BgBlob
