import { Reader } from '@stripe/terminal-js'
import { Title, View } from '@tabeo/scarf'
import Summary from 'components/terminal/Summary'
import { SUCCESS_ANIMATION_DELAY } from 'config'
import useLocalStorage from 'hooks/useLocalStorage'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import succeededImg from '../assets/succeeded.png'
import TerminalPaymentContext from '../Context'

function PaymentSucceeded({ reader }: { reader?: Reader }) {
  const { t } = useTranslation()
  const { state, dispatch, onSuccess } = useContext(TerminalPaymentContext)
  const { data: tp } = useTerminalPayment({ id: state.terminalPaymentId })
  const [, setLastUsedReader] = useLocalStorage('lastUsedReader', null)

  useEffect(() => {
    const receiptTimeout = setTimeout(() => {
      reader?.label &&
        setLastUsedReader({
          ...reader,
          isPlaceholder: true,
        })
      dispatch({ type: 'show-receipt' })
      onSuccess?.()
    }, SUCCESS_ANIMATION_DELAY)

    return () => {
      clearTimeout(receiptTimeout)
    }
  }, [dispatch, onSuccess, reader, setLastUsedReader])

  return (
    <View textAlign="center">
      <View as="img" src={succeededImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Payment succeeded')}</Title>
      <Summary data={tp} mt={6} />
    </View>
  )
}

export default PaymentSucceeded
