import { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type DivProps = ComponentPropsWithoutRef<'div'>

function Layout({ className, ...rest }: DivProps) {
  return (
    <div
      className={twMerge(
        'fixed inset-0 flex min-h-full flex-col overflow-y-scroll bg-white pt-10 desktop:relative desktop:flex-row desktop:overflow-y-clip desktop:pt-0',
        className
      )}
      {...rest}
    />
  )
}

function Main({ className, ...rest }: DivProps) {
  return (
    <div
      className={twMerge(
        'mx-auto w-full max-w-[868px] flex-1 p-4 xl:px-10 xl:py-6',
        className
      )}
      {...rest}
    />
  )
}

function Sidebar({ className, ...rest }: DivProps) {
  return (
    <div
      className={twMerge(
        'sticky top-0 hidden h-screen max-w-[278px] flex-1 border-l border-tabeo-sky-0 desktop:block',
        className
      )}
      {...rest}
    />
  )
}

function MobileFooter({ className, children, ...rest }: DivProps) {
  return (
    <div
      className={twMerge(
        'sticky bottom-0 border-t border-tabeo-sky-0 bg-tabeo-sky-3 py-4 desktop:hidden',
        className
      )}
      {...rest}
    >
      <div className="mx-auto max-w-[868px] px-5 xl:px-10">{children}</div>
    </div>
  )
}

Layout.Main = Main
Layout.Sidebar = Sidebar
Layout.MobileFooter = MobileFooter

export default Layout
