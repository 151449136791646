import api from '@tabeo/sharpei/utils/api'
import { Dispatch, useEffect, useRef } from 'react'
import { ActionKind } from './reducer'

const stateMap = {
  payment_gateway_creation_processing: 'connect_reader',
  payment_gateway_created: 'connect_reader',
  payment_gateway_reader_handoff_pending: 'connect_reader',
  payment_gateway_reader_handoff_succeeded: 'reader_connected',
  payment_gateway_reader_confirmed: 'payment_method_collected',
  payment_gateway_paid: 'payment_succeeded',
} as const

const actionMap = {
  connect_reader: 'reader-not-connected',
  reader_connected: 'collect-payment-method',
  collect_payment_method: 'payment-method-collected',
  payment_method_collected: 'payment-method-collected',
  payment_succeeded: 'payment-captured',
} as const

const delay = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

export function useServerDrivenFlow(
  dispatch: Dispatch<{ type: ActionKind; payload?: any }>
) {
  // Get sagaUUID from URL search params
  const url = new URL(window.location.href)
  const sagaUUID = url.searchParams.get('sagaUUID')

  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    async function pollSaga() {
      let saga: {
        generalStatus: string
        status: string
        message: string
      } = {
        generalStatus: 'new',
        status: 'new',
        message: '',
      }
      while (
        !['failed', 'completed'].includes(saga.generalStatus) &&
        isMounted.current
      ) {
        const response = await api.get(`/sagas/${sagaUUID}`)
        saga = response.saga

        const sagaStatus = saga.status as keyof typeof stateMap
        const state = stateMap[sagaStatus]
        if (state) {
          dispatch({ type: actionMap[state] })
        }
        await delay(250)
      }

      if (saga.generalStatus === 'failed') {
        // Handle errors during card confirmartion
        if (saga.status === 'payment_gateway_reader_unconfirmed') {
          dispatch({
            type: 'payment-declined',
            payload: {
              last_payment_error: {
                type: 'card_error',
                // Parse the error from our api to match the Stripe error codes
                decline_code: saga.message.split('_')[0],
              },
            },
          })
          // Handle busy reader
        } else if (
          saga.status === 'payment_gateway_reader_handoff_failed' &&
          saga.message === 'handoff_error_busy'
        ) {
          dispatch({
            type: 'reader-busy',
          })
          // Handle reader connection issue
        } else if (
          saga.status === 'payment_gateway_reader_handoff_failed' &&
          saga.message === 'handoff_general_error'
        ) {
          dispatch({
            type: 'reader-handoff-error',
          })
          // Handle errors during payment processing
        } else if (saga.status === 'payment_gateway_failed') {
          dispatch({
            type: 'payment-declined',
            payload: {
              last_payment_error: {
                code: 'payment_gateway_failed',
                decline_code: saga.message,
              },
            },
          })
        } else if (
          saga.status === 'payment_gateway_reader_handoff_cancellation'
        ) {
          dispatch({ type: 'cancel' })
        } else {
          dispatch({ type: 'error' })
        }
      } else {
        dispatch({ type: 'payment-captured' })
      }
    }

    if (sagaUUID) {
      pollSaga()
    }

    return () => {
      isMounted.current = false
    }
  }, [sagaUUID, dispatch])

  useEffect(() => {
    return () => {
      const url = new URL(window.location.href)
      url.searchParams.delete('sagaUUID')
      window.history.replaceState({}, '', url.toString())
    }
  }, [])
}
