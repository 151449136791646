import { Button, Caption, Title, usePromise, View } from '@tabeo/scarf'
import { toast } from '@tabeo/scarf2'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import dinoImg from '../assets/dino.png'
import TerminalPaymentContext from '../Context'

function Error() {
  const { t } = useTranslation()
  const { dispatch, state } = useContext(TerminalPaymentContext)
  const { resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const [triggerResend, { pending: resendPending }] = usePromise(
    async () => {
      if (resource && state.preferredReaderId && state.isServerDrivenFlow) {
        const saga = await resource.resendPaymentToReader(
          {
            readerId: state.preferredReaderId,
          },
          {
            whilePending: (saga: any) => saga,
          }
        )
        const url = new URL(window.location.href)
        url.searchParams.set('sagaUUID', saga.uuid)
        window.history.replaceState({}, '', url.toString())
      }
      dispatch({ type: 'reload-reader' })
    },
    {
      onError: () => {
        toast.error(t('Failed to resend payment to reader'))
      },
    }
  )

  return (
    <View textAlign="center">
      <View as="img" src={dinoImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Oops, looks like you got interrupted')}</Title>
      <Caption mt={1}>{t('Please do not refresh this page')}</Caption>
      <View mt={6}>
        <Button
          variant="secondary"
          onClick={triggerResend}
          loading={resendPending}
          width="100%"
        >
          {t('Try again')}
        </Button>
      </View>
    </View>
  )
}

export default Error
