import { ISdkManagedPaymentIntent } from '@stripe/terminal-js'

export type State = {
  step:
    | 'test-connection'
    | 'connect-reader'
    | 'collect-payment-method'
    | 'process-payment'
    | 'capture-payment'
    | 'payment-succeeded'
    | 'payment-receipt'
    | 'payment-failed'
    | 'reader-busy'
    | 'error'
    | 'cancelled'
    | 'reader-handoff-error'
  terminalPaymentId: number
  paymentIntent?: ISdkManagedPaymentIntent
  forceConnection?: boolean
  preferredReaderId?: string
  isServerDrivenFlow?: boolean
}

export type ActionKind =
  //
  | 'reader-connected'
  | 'reader-not-connected'
  | 'reader-busy'
  | 'reader-selected'
  | 'collect-payment-method'
  //
  | 'payment-method-collected'
  //
  | 'payment-approved'
  | 'payment-declined'
  | 'payment-captured'
  //
  | 'force-connection'
  | 'reload-reader'
  | 'show-receipt'
  //
  | 'reader-handoff-error'
  //
  | 'error'
  | 'cancel'

type Action = {
  type: ActionKind
  payload?: any
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'reader-connected':
      return { ...state, step: 'collect-payment-method' }
    case 'reader-not-connected':
      return { ...state, step: 'connect-reader' }
    case 'reader-busy':
      return {
        ...state,
        step: 'reader-busy',
      }
    case 'collect-payment-method':
      return {
        ...state,
        step: 'collect-payment-method',
      }
    case 'reader-selected':
      return {
        ...state,
        step: 'connect-reader',
        preferredReaderId: action.payload,
      }
    case 'payment-method-collected':
      return {
        ...state,
        step: 'process-payment',
        paymentIntent: action.payload,
      }
    case 'payment-approved':
      return {
        ...state,
        step: 'capture-payment',
      }
    case 'payment-declined':
      return {
        ...state,
        step: 'payment-failed',
        paymentIntent: action.payload,
      }
    case 'payment-captured':
      return {
        ...state,
        step: 'payment-succeeded',
      }
    case 'show-receipt':
      return {
        ...state,
        step: 'payment-receipt',
      }
    case 'force-connection':
      return {
        ...state,
        step: 'connect-reader',
        forceConnection: true,
      }
    case 'reload-reader':
      return {
        ...state,
        step: 'test-connection',
      }
    case 'reader-handoff-error':
      return {
        ...state,
        step: 'reader-handoff-error',
      }
    case 'error':
      return {
        ...state,
        step: 'error',
      }
    case 'cancel':
      return {
        ...state,
        step: 'cancelled',
      }
    default:
      throw new Error()
  }
}

export default reducer
