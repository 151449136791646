import * as Sentry from '@sentry/react'
import Connecting from 'components/terminal/Connecting'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { useContext, useEffect, useRef } from 'react'
import TerminalPaymentContext from '../Context'

function TestConnection() {
  const { clearReaderDisplay, sdk } = useStripeTerminal()
  const { dispatch, state } = useContext(TerminalPaymentContext)

  const didInit = useRef(false)

  useEffect(() => {
    const fn = async () => {
      try {
        const reader = sdk?.getConnectedReader()
        if (reader && reader?.id !== state.preferredReaderId) {
          await sdk?.disconnectReader()
          const error = new Error('Other reader is connected')
          Sentry.captureException(error, {
            extra: {
              preferredReaderId: state.preferredReaderId,
              reader,
            },
          })
          throw error
        }

        if (reader) {
          await clearReaderDisplay()
          dispatch({ type: 'reader-connected' })
        }

        if (!reader) {
          dispatch({ type: 'reader-not-connected' })
        }
      } catch {
        dispatch({ type: 'reader-not-connected' })
      }
    }

    if (!didInit.current && !state.isServerDrivenFlow) {
      didInit.current = true
      fn()
    }
  }, [
    clearReaderDisplay,
    dispatch,
    sdk,
    state.preferredReaderId,
    state.isServerDrivenFlow,
  ])

  return <Connecting minHeight="340px" />
}

export default TestConnection
