import { Reader } from '@stripe/terminal-js'
import { View } from '@tabeo/scarf'
import { Merge } from '@tabeo/scarf/dist/types/helpers'
import useStripeTerminal from 'hooks/useStripeTerminal'
import React, { useEffect, useMemo, useReducer } from 'react'
import TerminalPaymentContext from './Context'
import reducer from './reducer'
import { useServerDrivenFlow } from './server-driven-flow'
import CapturePayment from './steps/CapturePayment'
import CollectPaymentMethod from './steps/CollectPaymentMethod'
import ConnectReader from './steps/ConnectReader'
import Error from './steps/Error'
import PaymentFailed from './steps/PaymentFailed'
import PaymentReceipt from './steps/PaymentReceipt'
import PaymentSucceeded from './steps/PaymentSucceeded'
import ProcessPayment from './steps/ProcessPayment'
import ReaderBusy from './steps/ReaderBusy'
import ReaderHandoffError from './steps/ReaderHandoffError'
import TestConnection from './steps/TestConnection'

type TerminalPaymentProps = Merge<
  React.ComponentProps<typeof View>,
  {
    id: number
    reader?: Reader
    modal: {
      close: () => void
      isOpen?: boolean
    }
    onSuccess?: () => void
  }
>

function TerminalPayment({
  id,
  reader,
  modal,
  onSuccess = () => {},
}: TerminalPaymentProps) {
  // Get sagaUUID from URL search params
  const url = new URL(window.location.href)
  const sagaUUID = url.searchParams.get('sagaUUID')

  const isServerDrivenFlow = !!sagaUUID

  const initialStep = isServerDrivenFlow ? 'test-connection' : 'connect-reader'

  const [state, dispatch] = useReducer(reducer, {
    step: initialStep,
    terminalPaymentId: id,
    preferredReaderId: reader?.id,
    isServerDrivenFlow,
  })

  const terminalPaymentContextValue = useMemo(
    () => ({ state, dispatch, modal, onSuccess }),
    [state, dispatch, modal, onSuccess]
  )

  const { sdk } = useStripeTerminal()

  // Cleanup the reader when the component unmounts
  useEffect(() => {
    return () => {
      const reader = sdk?.getConnectedReader()
      if (reader) {
        sdk?.disconnectReader()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { step } = state

  useServerDrivenFlow(dispatch)

  useEffect(() => {
    if (step === 'cancelled') {
      modal.close()
    }
  }, [step, modal])

  return (
    <View
      maxWidth={['90%', '620px']}
      mx="auto"
      bg="white"
      borderRadius={1}
      boxShadow={1}
      p={[5, 10]}
    >
      <TerminalPaymentContext.Provider value={terminalPaymentContextValue}>
        {step === 'test-connection' && <TestConnection />}
        {step === 'connect-reader' && <ConnectReader />}
        {step === 'collect-payment-method' && <CollectPaymentMethod />}
        {step === 'process-payment' && <ProcessPayment />}
        {step === 'capture-payment' && <CapturePayment />}
        {step === 'payment-succeeded' && <PaymentSucceeded reader={reader} />}
        {step === 'payment-receipt' && <PaymentReceipt />}
        {step === 'payment-failed' && <PaymentFailed />}
        {step === 'reader-busy' && <ReaderBusy />}
        {step === 'error' && <Error />}
        {step === 'reader-handoff-error' && <ReaderHandoffError />}
      </TerminalPaymentContext.Provider>
    </View>
  )
}

export default TerminalPayment
