import Resource, { createResourceHook } from '@tabeo/resync'
import api from '@tabeo/sharpei/utils/api'
import { CardReader } from '@tabeo/ts-types'

interface Params {
  limit?: number
  offset?: number
  searchQuery?: string
}

interface Data {
  items: null | CardReader[]
  totalCount: number
}

class CardReaders extends Resource<Data, Params> {
  getInitialData() {
    return {
      items: null,
      totalCount: 0,
    }
  }

  getInitialConfig() {
    return {
      refreshInterval: 0, // disable auto-refresh to avoid stripe rate limiting
    }
  }

  async fetch() {
    const { limit = 100, offset = 0 } = this.params

    const endpoint = `/merchant/card-readers?limit=${limit}&offset=${offset}`

    const {
      data: { cardReaders },
      totalCount,
    } = await api.get(endpoint, {
      responseDataPath: 'data',
    })

    this.data = {
      totalCount,
      items: cardReaders,
    }

    return this.data
  }

  create = async (payload: { registrationCode: string; label: string }) => {
    const response = await api.post(`/merchant/card-readers`, payload)

    await this.fetch()
    return response
  }

  updateReaderById = async (id: string, payload: Partial<CardReader>) => {
    const response = await api.put(`/merchant/card-readers/${id}`, payload)

    await this.fetch()
    return response
  }
}

export default CardReaders
export const useCardReaders = createResourceHook(CardReaders)
