import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormError,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
  SpinnerIcon,
} from '@tabeo/scarf2'
import { AuthLayout, AuthLayoutBox } from 'components/layouts/AuthLayout'
import config, { getAppUrlForCountry } from 'config'
import z from 'lib/zod'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import { EmailClients } from './EmailClients'

const { COUNTRY } = config

const formSchema = z.object({
  otp: z.string(),
  email: z.string(),
})

type FormValues = z.infer<typeof formSchema>

export default function EnterOTP() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const email = params.get('email') || 'your email'
  const otp = params.get('otp') || ''

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onTouched',
    defaultValues: {
      otp,
      email,
    },
  })
  const { resource } = useAuth()

  const [loginError, setLoginError] = useState<ReactNode | null>(null)

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        setLoginError(null)
        // Delay 1.5 seconds
        await new Promise(resolve => {
          window.setTimeout(resolve, 500)
        })
        await resource?.login({
          token: values.otp,
          email: values.email,
        })
      } catch (error: any) {
        if (error.message.startsWith('invalid_country')) {
          const country = error.message.split(':')?.[1]
          const url = getAppUrlForCountry('merchant', country)
          return setLoginError(
            <span>
              <Trans>
                This account is registered in a different country, please use
                this link to login:
                <br />
                <a className="underline" href={`${url}/login`}>
                  {url}
                </a>
              </Trans>
            </span>
          )
        }

        if (error.message === 'invalid_role') {
          const url = getAppUrlForCountry('app', COUNTRY)
          return setLoginError(
            <span>
              <Trans>
                You are registered as a Consumer. Please go to{' '}
                <a className="underline" href={`${url}/login`}>
                  {url}
                </a>{' '}
                and log in from there
              </Trans>
            </span>
          )
        }

        if (error.message === 'deleted_user') {
          return setLoginError('This user was deleted, please contact support.')
        }

        form.setError('otp', {
          message: 'Incorrect code. Please try again.',
        })
      } finally {
        form.setValue('otp', '')
      }
    },
    [resource, form]
  )

  // Automatically submit the form if the OTP is already in the URL
  useEffect(() => {
    if (otp) {
      form.handleSubmit(onSubmit)()
    }
  }, [otp, form, onSubmit])

  // Count back down from 30 seconds
  const [timeout, setTimeout] = useState(30_000)
  useEffect(() => {
    const timer = window.setInterval(() => {
      if (timeout > 0) {
        setTimeout(timeout - 1_000)
      }
    }, 1_000)
    return () => clearInterval(timer)
  }, [setTimeout, timeout])

  return (
    <AuthLayout>
      <AuthLayoutBox>
        <h1 className="title mb-2">Check your email for a code</h1>
        <p className="mb-6 text-tabeo-ink-1">
          <span className="font-medium">{email}</span> has been sent a
          6-character code. It expires shortly, so please use it soon.
        </p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="otp"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center justify-between gap-2">
                    Sign-in code{' '}
                    {form.formState.isSubmitting && (
                      <div className="animate-spin">
                        <SpinnerIcon className="h-5 w-5 text-tabeo-primary-3" />
                      </div>
                    )}
                  </FormLabel>
                  <FormControl>
                    <InputOTP
                      maxLength={6}
                      onComplete={() => {
                        form.handleSubmit(onSubmit)()
                      }}
                      autoFocus
                      invalid={!!form.formState.errors.otp || !!loginError}
                      // Only allow numbers and alpha characters
                      pattern="^[0-9a-zA-Z]+$"
                      // Remove any dashes when pasting from email1UR-DWQ1UR-DWQ
                      pasteTransformer={value => value.replace('-', '')}
                      {...field}
                    >
                      <InputOTPGroup className="w-full">
                        <InputOTPSlot index={0} className="w-full uppercase" />
                        <InputOTPSlot index={1} className="w-full uppercase" />
                        <InputOTPSlot index={2} className="w-full uppercase" />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup className="w-full">
                        <InputOTPSlot index={3} className="w-full uppercase" />
                        <InputOTPSlot index={4} className="w-full uppercase" />
                        <InputOTPSlot index={5} className="w-full uppercase" />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormMessage>{loginError}</FormMessage>
                </FormItem>
              )}
            />
            <FormError />
            <EmailClients className="mt-6" />
            <p className="caption mt-6 text-tabeo-ink-2">
              {timeout > 0 ? (
                <>No code? Check your spam folder.</>
              ) : (
                <>
                  No code? Check your spam folder or{' '}
                  <Link
                    to={`/auth/request-otp?${params.toString()}`}
                    className="anchor text-sm"
                  >
                    request a new code
                  </Link>
                  .
                </>
              )}
            </p>
          </form>
        </Form>
      </AuthLayoutBox>
    </AuthLayout>
  )
}
