import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { plansorchestrator } from '@tabeo/platform-api-client'
import { Panel } from '@tabeo/scarf'
import { Button, TextInput, toast } from '@tabeo/scarf2'
import config from 'config'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ViewQrCodeDialog from './ViewQrCodeDialog'

type SharingProps = {
  data?: plansorchestrator.ModelsPlan
  vendor?: plansorchestrator.ModelsVendor
}

function Sharing({ data, vendor }: SharingProps) {
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)
  const publicUrl = `${config.CONSUMER_APP_URL}/plans/${vendor?.slug}/${data?.slug}`

  return (
    <Panel title={t('Sharing options')}>
      <div className="grid gap-1 p-5">
        <div className="caption font-medium">{t('Sharing link')}</div>
        <div className="caption text-tabeo-ink-2">
          {t('Share in emails or on your website')}
        </div>
        <div className="flex gap-2">
          <TextInput
            ref={inputRef}
            value={publicUrl}
            readOnly
            className="border-0"
            onClick={async () => {
              inputRef.current?.select()
              await navigator.clipboard.writeText(publicUrl)
              toast.success(t('Link has been copied'))
            }}
            wrapClassName="flex-1"
          />
          <a href={publicUrl} target="_blank" rel="noopener noreferrer">
            <Button className="h-10 w-10 p-2" variant="secondary">
              <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            </Button>
          </a>
        </div>
      </div>
      <div className="flex justify-between gap-2 border-t p-5">
        <div className="grid gap-1">
          <div className="caption font-medium">{t('QR Code')}</div>
          <div className="caption text-tabeo-ink-2">
            {t('Share plans with your patients')}
          </div>
        </div>
        <ViewQrCodeDialog url={publicUrl} data={data}>
          <Button variant="secondary" className="self-center">
            {t('View')}
          </Button>
        </ViewQrCodeDialog>
      </div>
    </Panel>
  )
}

export default Sharing
