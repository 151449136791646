import { Button, Spinner, Text, Title, usePromise, View } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { toast } from '@tabeo/scarf2'
import { useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import TerminalPaymentContext from './TerminalPayment/Context'

export type ConnectingProps = {
  titleProps?: ScarfProps
} & React.ComponentProps<typeof View>

function Connecting({ titleProps, ...rest }: ConnectingProps) {
  const { t } = useTranslation()
  const [showTroubleshooting, setShowTroubleshooting] = useState(false)
  const { modal, state } = useContext(TerminalPaymentContext)
  const { resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  useEffect(() => {
    const troubleshootingTimeout = setTimeout(() => {
      setShowTroubleshooting(true)
    }, 30_000)

    return () => {
      clearTimeout(troubleshootingTimeout)
    }
  }, [])

  async function handleCancel() {
    if (modal?.isOpen) {
      modal.close()
    }
    if (state.isServerDrivenFlow) {
      await resource?.cancel()
    }
  }

  const [triggerCancel, { pending }] = usePromise(handleCancel, {
    onError: () => {
      toast.error(t('Failed to cancel payment'))
    },
  })

  return (
    <View textAlign="center" {...rest}>
      <Spinner minHeight="160px" />
      <Title mt={6} {...titleProps}>
        {t('Connecting to your reader')}
      </Title>
      {showTroubleshooting ? (
        <>
          <Text mt={2} fontSize={1} mb={6}>
            <Trans>
              Could not communicate with the reader. Please make sure your
              reader is online and on the same network as your device.
            </Trans>
          </Text>
          <Button
            variant="transparent"
            as="a"
            href="https://intercom.help/tabeo/en/articles/8879412-reader-set-up-but-not-connecting"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Click for more details')}
          </Button>
          {modal?.isOpen && (
            <div className="mt-4">
              <Button
                variant="secondary"
                onClick={triggerCancel}
                loading={pending}
              >
                {t('Cancel')}
              </Button>
            </div>
          )}
        </>
      ) : modal?.isOpen ? (
        <div className="mt-4">
          <Button
            variant="transparent"
            onClick={triggerCancel}
            loading={pending}
          >
            {t('Cancel')}
          </Button>
        </div>
      ) : null}
    </View>
  )
}

export default Connecting
