import { Button } from '@tabeo/scarf2'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import successSvg from 'assets/success.svg'
import { Link } from 'react-router-dom'

function StripeOnboardingCompleted() {
  return (
    <LayoutSideNavbar>
      <Container>
        <div className="mx-auto max-w-[420px] space-y-6 rounded bg-white p-10 text-center shadow-md">
          <img className="mx-auto w-40 p-8" src={successSvg} />
          <div>
            <h2 className="title">Business details updated</h2>
            <p className="mt-1">
              Thank you for updating your details. We are now checking these and
              will update you shortly.
            </p>
          </div>
          <div>
            <Link to="/plans">
              <Button className="w-full">Back to dashboard</Button>
            </Link>
          </div>
        </div>
      </Container>
    </LayoutSideNavbar>
  )
}

export default StripeOnboardingCompleted
