import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FFRadioCards,
  RadioCards,
} from '@tabeo/scarf2'
import FormError from 'components/Form/FormError'
import { Field } from 'components/nnts/form/Field'
import { FORM_ERROR } from 'final-form'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { usePlanV2 } from 'resources/PlanV2'
import { z } from 'zod'
import { ReactComponent as ClosedLedIcon } from './closed-led.svg'
import { ReactComponent as PrivateLedIcon } from './private-led.svg'
import { ReactComponent as PublicLedIcon } from './public-led.svg'

export const statuses: Record<
  string,
  {
    label: string
    description: string
    icon: React.ElementType<React.SVGProps<SVGSVGElement>>
  }
> = {
  public: {
    label: 'Public',
    description: 'Listed publicly, for anyone to sign up',
    icon: PublicLedIcon,
  },
  private: {
    label: 'Private',
    description: 'Only people with an invite or link can sign up',
    icon: PrivateLedIcon,
  },
  closed: {
    label: 'Closed',
    description: 'Not open to new members',
    icon: ClosedLedIcon,
  },
} as const

const formSchema = z.object({
  status: z.string(),
})

export type FormValues = z.infer<typeof formSchema>

type EditStatusDialogProps = {
  children: React.ReactNode
}

function EditStatusDialog({ children }: EditStatusDialogProps) {
  const { data, resource } = usePlanV2()
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = async (values: any) => {
    let parsedValues: FormValues
    try {
      parsedValues = formSchema.parse(values)
    } catch (error) {
      return {
        [FORM_ERROR]: t('Invalid form values'),
      }
    }

    try {
      await resource?.updateStatus(parsedValues.status)
      setOpen(false)
    } catch {
      return {
        [FORM_ERROR]: t('Failed to update status'),
      }
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle className="title">Update plan status</DialogTitle>
        </DialogHeader>
        <Form onSubmit={handleSubmit} initialValues={{ status: data?.status }}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <>
                <DialogBody>
                  <Field
                    name="status"
                    component={FFRadioCards}
                    label={t('Sharing status')}
                    subLabel={t(
                      'Configure how your plan is shared to new members'
                    )}
                    className="!space-y-2"
                  >
                    {Object.entries(statuses).map(([key, status]) => (
                      <RadioCards.Option
                        key={key}
                        value={key}
                        className="flex items-center justify-start gap-x-2 first:mt-1"
                      >
                        <RadioCards.OptionDot />
                        <div>
                          <div className="font-medium">{status.label}</div>
                          <div>{status.description}</div>
                        </div>
                      </RadioCards.Option>
                    ))}
                  </Field>
                </DialogBody>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button type="button">Cancel</Button>
                  </DialogClose>
                  <Button variant="primary" type="submit" loading={submitting}>
                    Confirm
                  </Button>
                  <FormError />
                </DialogFooter>
              </>
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default EditStatusDialog
