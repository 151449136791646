import { State } from '@tabeo/scarf/dist/components/DataTable/context'
import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export function mapReconciliationStateToParams(state: State) {
  const { activeTabs, activeFilters } = state

  const status = activeTabs?.flatMap(tab => tab.statuses)

  const selectedDate = activeFilters?.date?.value?.value
  const startDate = selectedDate ? moment(selectedDate) : undefined
  const endDate = selectedDate
    ? moment(selectedDate).add(1, 'day').startOf('day')
    : undefined

  const chargedAt = selectedDate
    ? `date_range("${startDate?.format('YYYY-MM-DD') || ''}"-"${
        endDate?.format('YYYY-MM-DD') || ''
      }")`
    : undefined

  const amountRange = activeFilters?.amount?.value
  const amount = amountRange
    ? `range(${amountRange.min || ''}-${amountRange.max || ''})`
    : undefined

  const merchantClinicianID = activeFilters?.clinician?.value?.map(
    ({ value }: any) => value
  )

  const readerSerialNumber = activeFilters?.reader?.value?.value

  return {
    ...state,
    conditions: {
      status,
      chargedAt,
      amount,
      merchantClinicianID,
      readerSerialNumber,
    },
  }
}
