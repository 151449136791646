import { DataTable, GridTable, Title } from '@tabeo/scarf'
import { DataTableProps } from '@tabeo/scarf/dist/components/DataTable'
import { HeadProps } from '@tabeo/scarf/dist/components/DataTable/Head'
import { MerchantClinician } from '@tabeo/ts-types'
import AmountFilter from 'components/AmountFilter'
import DateFilter from 'components/DateFilter'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { clinicianSort } from 'components/MerchantClinicianSelect'
import DatoNotifications from 'components/Notifications/DatoNotifications'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useCardReaders } from 'resources/CardReaders'
import { useMerchant } from 'resources/Merchant'
import { useReconciliationPayments } from 'resources/ReconciliationPayments'
import formatClinicianName from 'utils/formatClinicianName'
import ListItem from './ListItem'
import Stats from './Stats'
import { mapReconciliationStateToParams } from './utils'

function Reconciliation() {
  const { t } = useTranslation()
  const { data: merchant } = useMerchant()
  const { merchantClinicians } = merchant || { merchantClinicians: [] }
  const { data: cardReaders } = useCardReaders()

  const isCliniciansTreatmentsEnabled =
    merchant?.flags?.is_clinicians_treatments_enabled

  const filters = [
    {
      name: 'amount',
      label: 'Amount',
      initialValue: {
        min: '',
        max: '',
      },
      component: AmountFilter,
    },
    isCliniciansTreatmentsEnabled && {
      name: 'clinician',
      label: 'Clinician',
      loadOptions: (q: string) =>
        merchantClinicians
          .sort(clinicianSort)
          .filter(
            (mc: MerchantClinician) =>
              mc.clinician?.firstName.toLowerCase().includes(q.toLowerCase()) ||
              mc.clinician?.lastName.toLowerCase().includes(q.toLowerCase()) ||
              formatClinicianName(mc).toLowerCase().includes(q.toLowerCase())
          )
          .map((mc: MerchantClinician) => ({
            value: mc.id,
            label: formatClinicianName(mc),
          })),
      initialValue: [],
      filtering: true,
    },
    cardReaders?.items?.length && {
      name: 'reader',
      label: 'Reader',
      initialValue: '',
      options: cardReaders?.items?.map(cr => ({
        value: cr.serial_number,
        label: cr.label,
      })),
    },
    {
      name: 'date',
      label: 'Date',
      initialValue: '',
      component: DateFilter,
      daypickerProps: {
        modifiers: {
          disabled: {
            after: moment().toDate(),
          },
        },
      },
    },
  ].filter(Boolean) as DataTableProps['filters']

  return (
    <LayoutSideNavbar>
      <Container maxWidth="992px">
        <DatoNotifications />
        <Title>{t('Reconciliation')}</Title>
        <p className="body mb-6 text-tabeo-ink-1">
          Reconcile daily takings against your practice management software.
        </p>
        <DataTable
          data-testid="link-payments-table"
          mapStateToParams={mapReconciliationStateToParams}
          // @ts-ignore
          resourceHook={useReconciliationPayments}
          paginationLimit={100}
          searchPlaceholder={t('Search by ID or patient name')}
          filters={filters}
          initialState={{
            orderBy: { field: 'chargedAt', direction: 'desc' },
            activeFilters: {
              date: {
                value: {
                  value: new Date().setHours(0, 0, 0, 0),
                  label: moment().format('DD MMM YYYY'),
                },
                mode: undefined,
              },
            },
          }}
        >
          {({ items }) => (
            <GridTable
              templateColumns={
                isCliniciansTreatmentsEnabled
                  ? [
                      'minmax(100px, auto) minmax(105px, auto) minmax(170px, auto) minmax(170px, auto) minmax(170px, auto) minmax(100px, auto) minmax(140px, auto)',
                    ]
                  : [
                      'minmax(100px, auto) minmax(105px, auto) minmax(170px, auto) minmax(170px, auto) minmax(100px, auto) minmax(140px, auto)',
                    ]
              }
              minWidth="650px"
            >
              <Stats
                className={
                  isCliniciansTreatmentsEnabled ? 'col-span-7' : 'col-span-6'
                }
              />
              <DataTable.Head
                columns={
                  [
                    { label: 'ID' },
                    {
                      label: 'Amount',
                      orderBy: 'amount',
                      cellProps: {
                        justifyContent: 'flex-end',
                      },
                    },
                    { label: 'Patient' },
                    { label: 'Treatment' },
                    isCliniciansTreatmentsEnabled && { label: 'Clinician' },
                    { label: 'Product' },
                    {
                      label: 'Date',
                      orderBy: 'chargedAt',
                      cellProps: {
                        justifyContent: 'flex-end',
                      },
                    },
                  ].filter(Boolean) as HeadProps['columns']
                }
                // @ts-ignore
                display={['none', 'contents']}
              />
              {items.map(item => (
                <ListItem
                  key={item.id}
                  data={item}
                  isCliniciansTreatmentsEnabled={isCliniciansTreatmentsEnabled}
                />
              ))}
            </GridTable>
          )}
        </DataTable>
      </Container>
    </LayoutSideNavbar>
  )
}

export default Reconciliation
