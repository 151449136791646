import { plansorchestrator } from '@tabeo/platform-api-client'
import { Panel, usePromise } from '@tabeo/scarf'
import { useTranslation } from 'react-i18next'
import { Button, toast } from '@tabeo/scarf2'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import bank from 'components/PaymentMethodPanel/assets/bank.svg'

export type PaymentMethodPanelProps = {
  data?: plansorchestrator.ModelsGetSubscriptionAggregate
} & React.ComponentProps<typeof Panel>

export default function PaymentMethodPanel({
  data,
  ...rest
}: PaymentMethodPanelProps) {
  const { t } = useTranslation()
  const { resource } = useSubscriptionV2()

  const [trigger, { pending }] = usePromise(async () => {
    if (resource?.sendDirectDebitUpdate) {
      await resource.sendDirectDebitUpdate()
      toast.success(t('Email sent  to customer'))
    }
  })

  if (
    ['new', 'sent', 'sign_up', 'pending'].includes(
      data?.subscription?.status || ''
    )
  ) {
    return null
  }

  const paymentMethod = data?.paymentMethod as {
    bank_name: string
    account_number: string
  } | null

  const paymentProvider = data?.subscription?.paymentProvider

  return (
    <Panel
      header={
        <>
          <Panel.Title>{t('Payment method')}</Panel.Title>
          {paymentProvider === 'stripe' && (
            <Button
              size="sm"
              variant="secondary"
              onClick={trigger}
              loading={pending}
            >
              {t('Prompt update')}
            </Button>
          )}
        </>
      }
      {...rest}
    >
      <div className="p-5">
        {paymentMethod ? (
          <div className="flex justify-between">
            <div className="flex items-center gap-3">
              <img src={bank} />
              <div className="text-base">{paymentMethod?.bank_name}</div>
            </div>
            <div className="flex items-center gap-0.5 text-tabeo-ink-2">
              <div className="text-[8px]">●●●●</div>
              <div>{paymentMethod?.account_number}</div>
            </div>
          </div>
        ) : (
          <div className="caption text-tabeo-ink-2">
            {t(
              'Information missing - prompt the member to update their details'
            )}
          </div>
        )}
      </div>
    </Panel>
  )
}
