import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormError,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TextInput,
} from '@tabeo/scarf2'
import {
  authEmail,
  AuthLayout,
  AuthLayoutBox,
} from 'components/layouts/AuthLayout'
import z from 'lib/zod'
import { useForm } from 'react-hook-form'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

const formSchema = z.object({
  email: authEmail,
})

type FormValues = z.infer<typeof formSchema>

export default function ResetPassword() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const email = params.get('email') || ''

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onTouched',
    defaultValues: {
      email,
    },
  })
  const { resource } = useAuth()

  async function onSubmit(values: FormValues) {
    try {
      await resource?.requestPasswordResetEmail(values)
    } catch {
      form.setError('root', {
        message: 'Something went wrong, please try again later.',
      })
    }
  }

  return (
    <AuthLayout>
      <AuthLayoutBox>
        <h1 className="title mb-2">Reset your password</h1>
        {form.formState.isSubmitSuccessful ? (
          <>
            <p className="mb-6 text-tabeo-ink-1">
              <span className="font-medium">{form.getValues().email}</span> has
              been sent a password reset link. If you don’t see it, please check
              your spam folder.
            </p>
            <Link
              to={`/auth/password-login?email=${encodeURIComponent(
                form.getValues().email
              )}`}
              className="anchor"
            >
              <Button className="w-full" variant="primary">
                Back to sign in
              </Button>
            </Link>
          </>
        ) : (
          <>
            <p className="mb-6 text-tabeo-ink-1">
              Enter your email address and we’ll send you a link to reset your
              password.
            </p>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-5"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email address</FormLabel>
                      <FormControl>
                        <TextInput
                          placeholder="e.g. name@email.co.uk"
                          type="email"
                          onClear={
                            field.value ? () => field.onChange('') : undefined
                          }
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="submit"
                  variant="primary"
                  loading={form.formState.isSubmitting}
                  className="w-full"
                >
                  Send reset link
                </Button>
                <FormError />
              </form>
            </Form>
          </>
        )}
      </AuthLayoutBox>
    </AuthLayout>
  )
}
