import config from 'config'
import {
  BillSubmitParams,
  CheckoutPaymentSubmitParams,
  FinanceCreatePayload,
  FinanceSubmitParams,
  LoanOptionWithRiskProfile,
  NNTSState,
  ProviderCtx,
} from 'pages/NNTS/types'
import {
  getLoanOptionByPaymentMethod,
  getMerchantClinicianById,
  getMerchantTreatmentById,
} from 'pages/NNTS/utils'
import Bills from 'resources/Bills'
import LinkPayments from 'resources/LinkPayments'
import Offers from 'resources/Offers'
import TerminalPayments from 'resources/TerminalPayments'
import { NormalizedMerchant } from 'types/NormalizedMerchant'

export async function handlePaymentSubmission(
  state: NNTSState,
  merchant: NormalizedMerchant,
  modals: ProviderCtx['modals']
) {
  const { payment } = state

  if (!payment) {
    throw new Error('Missing form data')
  }

  const isFinance = payment?.paymentMethod?.startsWith('finance')
  const isBill = payment?.paymentMethod === 'bill'
  const isLink = payment?.paymentMethod === 'link'
  const isTerminal = payment?.paymentMethod === 'terminal'

  if (isFinance) {
    const offer = await handleFinanceSubmission(state, merchant)
    modals.cartSummaryModalRef?.current?.close()
    modals.legacySuccessModalRef?.current?.open(offer)
    return
  }

  if (isBill) {
    const bill = await handleBillSubmission(state, merchant)
    modals.cartSummaryModalRef?.current?.close()
    modals.legacySuccessModalRef?.current?.open({ ...bill, type: 'bill' })
    return
  }

  if (isLink) {
    const linkPayment = await handleLinkSubmission(state, merchant)
    modals.cartSummaryModalRef?.current?.close()
    modals.linkPaymentModalRef?.current?.open({ id: linkPayment.id })
    return
  }

  if (isTerminal) {
    const terminalPayment = await handleTerminalSubmission(state, merchant)
    modals.cartSummaryModalRef?.current?.close()
    modals.terminalPaymentModalRef?.current?.open({
      id: terminalPayment.id,
      reader: payment?.reader,
    })
    return
  }

  throw new Error('Unknown payment method')
}

export async function handleBillSubmission(
  formData: BillSubmitParams,
  merchant: NormalizedMerchant
) {
  const { treatment, patient, payment, clinician, invoices } = formData

  if (!treatment || !patient || !payment) {
    throw new Error('Missing form data')
  }

  const mt = getMerchantTreatmentById(treatment.merchantTreatmentId, merchant)
  const mc = getMerchantClinicianById(clinician?.merchantClinicianId, merchant)

  const payload = {
    amount: Number(payment.price),
    currency: payment.currency,
    consumerFirstName: patient.firstName,
    consumerLastName: patient.lastName,
    consumerGender: patient.gender,
    consumerEmail: patient.email,
    consumerPhoneNumber: patient.mobileNumber,
    scheduledAt: payment.scheduledAt,
    title:
      treatment.otherTreatmentName ||
      mt?.treatment?.name ||
      treatment.treatmentName,
    treatmentCategory: treatment.otherTreatmentCategory,
    // backend support is missing for C&T
    merchantTreatmentID: mt?.id,
    merchantClinicianID: mc?.id,
    basketReference: invoices?.references?.join(', '),
  }

  return Bills.create(payload)
}

export async function handleFinanceSubmission(
  formData: FinanceSubmitParams,
  merchant: NormalizedMerchant
) {
  const { treatment, patient, payment, clinician, invoices } = formData

  if (!treatment || !patient || !payment) {
    throw new Error('Missing form data')
  }

  const mt = getMerchantTreatmentById(treatment.merchantTreatmentId, merchant)
  const mc = getMerchantClinicianById(clinician?.merchantClinicianId, merchant)

  const loanOption = getLoanOptionByPaymentMethod(
    payment.paymentMethod,
    Number(payment.price),
    merchant
  )

  if (!loanOption) {
    throw new Error('Missing loan option')
  }

  const payload: FinanceCreatePayload = {
    customFields: {
      consumerVersion: 3,
    },
    consumerFirstName: patient.firstName,
    consumerLastName: patient.lastName,
    consumerGender: patient.gender,
    consumerEmail: patient.email,
    consumerPhoneNumber: patient.mobileNumber,
    title:
      treatment.otherTreatmentName ||
      mt?.treatment?.name ||
      treatment.treatmentName,
    treatmentCategory: treatment.otherTreatmentCategory,
    merchantTreatmentID: mt?.id,
    merchantClinicianID: mc?.id,
    initialPaymentPlanOptions: loanOption.customerTermOptions.map(
      (o: LoanOptionWithRiskProfile) => o.numberOfMonths
    ),
    riskProfileNames: [
      ...new Set(loanOption.customerTermOptions.map(o => o.profile.name)),
    ],
    price: Number(payment.price),
    currency: payment.currency,
    staffPerson: patient.staffPerson,
    leadId: payment.leadId,
    type: 'pay_over_time',
    reference: payment.reference,
    basketReference: invoices?.references?.join(', '),
  }

  try {
    const offer = await Offers.create(payload)

    // Apply with client flow
    if (payload.staffPerson) {
      const newTab = window.open('', '_blank')
      if (newTab) {
        newTab.document.write('Loading...')
        const { publicAccessToken } = offer
        newTab.location.href = `${config.CONSUMER_APP_URL}/how-it-works/${publicAccessToken}`
      }
    }

    return offer
  } catch (e: any) {
    // Handle api errors
    if (e.response?.data?.data) {
      // If api error we possibly need to remap some of the fields
      const { consumerError, consumerEmail } = e.response?.data?.data
      if (consumerEmail) {
        e.response.data.data.email = consumerEmail
      }

      // This happens when consumer is registered in a different country
      if (consumerError) {
        return {
          formError: consumerError,
        }
      }
    }

    throw e
  }
}

export async function handleLinkSubmission(
  formData: CheckoutPaymentSubmitParams,
  merchant: NormalizedMerchant
) {
  const { payment, patient, treatment, clinician, invoices } = formData

  if (!payment) {
    throw new Error('Missing form data')
  }

  const mt = getMerchantTreatmentById(treatment?.merchantTreatmentId, merchant)
  const mc = getMerchantClinicianById(clinician?.merchantClinicianId, merchant)

  const treatmentCategory =
    treatment?.otherTreatmentCategory || treatment?.treatmentCategory
  const treatmentName =
    treatment?.otherTreatmentName || treatment?.treatmentName

  const payload = {
    amount: Number(payment.price),
    currency: payment.currency,
    patient: {
      ...patient,
      phoneNumber: patient?.mobileNumber,
    },
    treatmentName,
    treatmentCategory,
    merchantTreatmentID: mt?.id,
    merchantClinicianID: mc?.id,
    basketReference: invoices?.references?.join(', '),
  }

  return LinkPayments.create(payload)
}

export async function handleTerminalSubmission(
  formData: CheckoutPaymentSubmitParams,
  merchant: NormalizedMerchant
) {
  const { payment, patient, treatment, clinician, invoices } = formData

  if (!payment) {
    throw new Error('Missing form data')
  }

  const mt = getMerchantTreatmentById(treatment?.merchantTreatmentId, merchant)
  const mc = getMerchantClinicianById(clinician?.merchantClinicianId, merchant)

  const treatmentCategory =
    treatment?.otherTreatmentCategory || treatment?.treatmentCategory
  const treatmentName =
    treatment?.otherTreatmentName || treatment?.treatmentName

  const isServerDrivenFlow =
    merchant.flags?.is_server_driven_reader_connection_capable

  const payload = {
    amount: Number(payment.price),
    currency: payment.currency,
    patient: {
      ...patient,
      phoneNumber: patient?.mobileNumber,
    },
    treatmentName,
    treatmentCategory,
    merchantTreatmentID: mt?.id,
    merchantClinicianID: mc?.id,
    basketReference: invoices?.references?.join(', '),
    readerID: payment.reader?.id,
    force: payment.reader?.is_busy,
  }

  const terminalPayment = await TerminalPayments.create(
    payload,
    isServerDrivenFlow
  )

  // Add terminalPayment.sagaUUID to URL search params if isServerDrivenFlow
  if (isServerDrivenFlow) {
    const url = new URL(window.location.href)
    url.searchParams.set('sagaUUID', terminalPayment.sagaUUID)
    window.history.pushState({}, '', url.toString())
  }

  return terminalPayment
}
