import Resource, { createResourceHook, ResourceConfig } from '@tabeo/resync'
import api from 'utils/platform-api'
import { plansorchestrator } from '@tabeo/platform-api-client'
import { useMerchant } from './Merchant'

interface Params {
  merchantId: string | number
}

class Vendor extends Resource<plansorchestrator.ModelsVendor, Params> {
  async fetch() {
    const response =
      await api.plansorchestrator.plans.apiV1OrchestratorPlansVendorsGet({
        legacyId: Number(this.params.merchantId),
        authorization: '', // it's required, but the auth middleware will handle it
      })

    this.data = response.vendor

    return this.data
  }

  getStripeOnboardingUrl = async () => {
    const response =
      await api.plansorchestrator.plans.apiV1OrchestratorPlansStripeAccountsLinkGet()

    return response.url
  }
}

export default Vendor
export const useResource = createResourceHook(Vendor)
export function useVendor(
  p?: Params | (() => Params),
  config?: ResourceConfig
) {
  const { data: merchant } = useMerchant(undefined, {
    isEnabled: !p,
  })

  let params: Params | undefined
  if (p instanceof Function) {
    params = p()
  } else {
    params = p
  }

  const merchantIdParam = params?.merchantId || merchant?.id

  return useResource(() => {
    if (!merchantIdParam) {
      throw new Error('Merchant ID is null')
    }
    return {
      merchantId: Number.isNaN(Number(merchantIdParam))
        ? merchantIdParam
        : +merchantIdParam,
    }
  }, config)
}
