import { plansorchestrator } from '@tabeo/platform-api-client'
import { Panel } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import EditStatusDialog, { statuses } from './EditStatusDialog'

type StatusPanelProps = {
  data?: plansorchestrator.ModelsPlan
}

function StatusPanel({ data }: StatusPanelProps) {
  const { t } = useTranslation()

  const {
    label,
    description,
    icon: Icon,
  } = data?.status ? statuses[data.status] : statuses.public

  return (
    <Panel
      header={
        <>
          <Panel.Title>{t('Plan status')}</Panel.Title>
          <EditStatusDialog>
            <Button size="sm" variant="secondary">
              {t('Edit')}
            </Button>
          </EditStatusDialog>
        </>
      }
    >
      <div className="flex items-start gap-1 p-5">
        <Icon className="my-px" />
        <div>
          <div className="caption">{label}</div>
          <div className="caption text-tabeo-ink-2">{description}</div>
        </div>
      </div>
    </Panel>
  )
}

export default StatusPanel
