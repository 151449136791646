import { plansorchestrator } from '@tabeo/platform-api-client'
import { Modal, NativeSelect, View } from '@tabeo/scarf'
import { Button, FFRadioTabs, FFTextInput } from '@tabeo/scarf2'
import FormError from 'components/Form/FormError'
import MerchantClinicianSelect from 'components/MerchantClinicianSelect'
import { Field } from 'components/nnts/form/Field'
import config from 'config'
import { FORM_ERROR } from 'final-form'
import React from 'react'
import { Form as FinalForm, FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useSubscriptionsV2 } from 'resources/SubscriptionsV2'
import { zodEmailPCA, zodValidator } from 'utils/zod-validation'
import { z } from 'zod'

const formSchema = z.object({
  planId: z.string(),
  merchantClinician: z
    .object({
      clinician: z.object({
        id: z.number(),
      }),
    })
    .nullable()
    .optional(),
  customer: z.object({
    gender: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email().pipe(zodEmailPCA),
  }),
})

export type FormValues = z.infer<typeof formSchema>

export type FormProps = {
  plans: plansorchestrator.ModelsSelectPlansAggregate[]
  onFormChange: (values: Partial<FormValues>) => void
  modalRef: React.RefObject<Modal>
}

export default function Form({ plans, onFormChange, modalRef }: FormProps) {
  const { t } = useTranslation()
  const { resource } = useSubscriptionsV2()

  const handleSubmit = async (values: any) => {
    let parsedValues: FormValues
    try {
      parsedValues = await formSchema.parseAsync(values)
    } catch (error) {
      return {
        [FORM_ERROR]: 'Invalid form values',
      }
    }

    try {
      const result = await resource?.create({
        customer: parsedValues.customer,
        planId: parsedValues.planId,
        clinicianId: parsedValues.merchantClinician?.clinician.id,
      })
      modalRef.current?.open({ id: result?.id })
    } catch (error) {
      return {
        [FORM_ERROR]: t('Failed to invite a member'),
      }
    }
  }

  const initialValues = React.useMemo(() => ({ customer: {} }), [])

  const groupedPlansByName = React.useMemo(() => {
    const grouped = plans
      .filter(p => ['public', 'private'].includes(p.status || ''))
      .reduce((acc, plan) => {
        const { name } = plan
        if (!name) {
          return acc
        }

        return {
          ...acc,
          [name]: [...(acc[name] || []), plan],
        }
      }, {} as Record<string, plansorchestrator.ModelsSelectPlansAggregate[]>)

    return Object.entries(grouped).map(([name, plans]) => ({
      name,
      plans,
    }))
  }, [plans])

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={zodValidator(formSchema)}
    >
      {({
        handleSubmit,
        form: { reset, resetFieldState, getRegisteredFields },
        submitting,
        validating,
        valid,
      }) => (
        <form
          onSubmit={async e => {
            try {
              const error = await handleSubmit(e)
              if (!error && valid) {
                reset()

                // https://github.com/final-form/final-form/issues/317
                // reset() doesn't clean some metadata
                getRegisteredFields().forEach(field => {
                  // Handle conditional fields
                  if (getRegisteredFields().includes(field)) {
                    resetFieldState(field)
                  }
                })
              }
              return error
            } catch (e) {
              return e
            }
          }}
        >
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              onFormChange(values as Partial<FormValues>)
            }}
          />
          <View boxShadow={1} borderRadius={1} border={1} bg="white">
            <div className="space-y-5 p-7">
              <h3 className="heading">{t('Invite a member')}</h3>
              <Field
                name="planId"
                label={t('Plan')}
                component={NativeSelect}
                maxWidth="100%"
                options={plans.map(p => ({ value: p.id, label: p.name }))}
              >
                <option value="" disabled>
                  {t('Select from the list')}
                </option>
                {groupedPlansByName.map(({ name, plans }) => (
                  // Keep this commented out for now until we decide final design
                  // plans.length === 1 ? (
                  //   <option key={plans[0].id} value={plans[0].id}>
                  //     {plans[0].name}
                  //   </option>
                  // ) : (
                  <optgroup key={name} label={name}>
                    {plans.map(plan => (
                      <option key={plan.id} value={plan.id}>
                        {plan.internalReference || plan.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Field>
              <Field
                name="merchantClinician"
                label={t('Preferred clinician')}
                component={MerchantClinicianSelect}
                variant="outline-v2"
                optional
                maxWidth="100%"
              />
            </div>
            <div className="space-y-5 border-t border-tabeo-sky-1 p-7">
              <h3 className="heading">{t('Member')}</h3>
              <div className="grid items-start gap-4 sm:grid-cols-2">
                <Field<string>
                  component={FFRadioTabs}
                  name="customer.gender"
                  label={t('Title')}
                  items={[
                    { value: 'm', label: t('Mr.') },
                    { value: 'f', label: t('Ms.') },
                  ]}
                />
              </div>
              <div className="grid items-start gap-4 sm:grid-cols-2">
                <Field<string>
                  component={FFTextInput}
                  name="customer.firstName"
                  label={t('First name')}
                  placeholder={t('E.g. John')}
                  format={v => (v ? v.capitalize() : '')}
                  clearable
                />

                <Field<string>
                  component={FFTextInput}
                  name="customer.lastName"
                  label={t('Last name')}
                  placeholder={t('E.g. Smith')}
                  format={v => (v ? v.capitalize() : '')}
                  clearable
                />
              </div>
              <Field
                component={FFTextInput}
                name="customer.email"
                label={t('Email address')}
                placeholder={t('E.g. john@email.com')}
                onKeyDown={(e: any) => e.stopPropagation()}
              />
            </div>
            <div className="px-7 pb-6">
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                loading={submitting}
                disabled={validating || config.IS_PLANS_2_INVITE_DISABLED}
              >
                {t('Invite to Membership')}
              </Button>
              <FormError />
            </div>
          </View>
        </form>
      )}
    </FinalForm>
  )
}
