import { Modal } from '@tabeo/scarf'
import api from '@tabeo/sharpei/utils/api'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import LinkPaymentModal from 'components/LinkPayment/Modal/Modal'
import useBreakpoints from 'components/nnts/hooks/useBreakpoints'
import StripeTerminalProvider from 'components/terminal/StripeTerminalProvider'
import TerminalPaymentModal from 'components/terminal/TerminalPayment/Modal'
import SuccessModal from 'pages/NewTransaction/SuccessModal'
import { useEffect, useRef } from 'react'
import { useMerchant } from 'resources/Merchant'
import Flow from './components/flow'
import MobileSummaryReviewModal from './components/mobileSummaryReview'
import { newTransactionCtx, Provider } from './components/provider'
import { modules } from './modules'

function NewTransaction() {
  const cartSummaryModalRef = useRef<Modal>(null)
  const legacySuccessModalRef = useRef<Modal>(null)
  const linkPaymentModalRef = useRef<Modal>(null)
  const terminalPaymentModalRef = useRef<Modal>(null)
  const breakpoints = useBreakpoints()

  const { data } = useMerchant()
  const isServerDrivenFlow =
    data?.flags?.is_server_driven_reader_connection_capable

  // Handle server driven flows
  useEffect(() => {
    async function resumeServerDrivenFlow() {
      const url = new URL(window.location.href)
      const sagaUUID = url.searchParams.get('sagaUUID')
      if (sagaUUID) {
        const { saga } = await api.get(`/sagas/${sagaUUID}`)
        terminalPaymentModalRef.current?.open({
          id: saga.projections.terminalPayment.id,
          reader: {
            id: saga.projections.reader.id,
          },
        })
      }
    }
    resumeServerDrivenFlow()
  }, [])

  return (
    <LayoutSideNavbar height={['auto', '100vh']}>
      <StripeTerminalProvider skipInitialization={isServerDrivenFlow}>
        <Provider
          modals={{
            cartSummaryModalRef,
            legacySuccessModalRef,
            linkPaymentModalRef,
            terminalPaymentModalRef,
          }}
          modules={modules}
        >
          <SuccessModal innerRef={legacySuccessModalRef} />
          <newTransactionCtx.Consumer>
            {({ reset }) => (
              <>
                <LinkPaymentModal ref={linkPaymentModalRef} />
                <TerminalPaymentModal
                  ref={terminalPaymentModalRef}
                  onSuccess={reset}
                />
              </>
            )}
          </newTransactionCtx.Consumer>
          {!breakpoints.lg && (
            <MobileSummaryReviewModal ref={cartSummaryModalRef} />
          )}
          <Flow />
        </Provider>
      </StripeTerminalProvider>
    </LayoutSideNavbar>
  )
}

export default NewTransaction
