import { useCallback } from 'react'

type HubSpotWidgetStatus = {
  loaded: boolean // Whether the widget iframe has loaded
}

type HubSpotWidgetMethods = {
  /**
   * Loads the chat widget. Throttled to one call per second.
   * @param options - Optional parameters for loading the widget.
   */
  load: (options?: { widgetOpen?: boolean }) => void

  /**
   * Refreshes and re-renders the widget's information. Throttled to one call per second.
   * @param options - Optional parameters for refreshing the widget.
   */
  refresh: (options?: { openToNewThread?: boolean }) => void

  /**
   * Opens the widget if it is not already open.
   */
  open: () => void

  /**
   * Closes the widget if it is not already closed.
   */
  close: () => void

  /**
   * Removes the widget from the page. If the widget is not present, this does nothing.
   */
  remove: () => void

  /**
   * Returns an object containing the current status of the widget.
   * @returns The status of the widget.
   */
  status: () => HubSpotWidgetStatus
}

type HubSpotConversations = {
  widget: HubSpotWidgetMethods
  resetAndReloadWidget: () => void
}

type HsConversationsSettings = {
  /**
   * Whether the widget should implicitly load or wait until the widget.load method is called.
   * @default true
   */
  loadImmediately?: boolean

  /**
   * Specify a selector (#some-id) to embed the chat widget in a specific location on the page.
   * Widget will be embedded inline within that DOM node and will remain open until it is removed with widget.remove.
   * Learn more about styling embedded chat widgets.
   * @default ""
   */
  inlineEmbedSelector?: string

  /**
   * Control behavior of the cookie banner for all chat widgets on the page. Options include:
   * - false (default): uses the chat widget's settings.
   * - true: presents cookie banners when the widget is loaded.
   * - ON_WIDGET_LOAD: same as true.
   * - ON_EXIT_INTENT: enable cookie banners when the user exhibits an exit intent.
   * @default false
   */
  enableWidgetCookieBanner?: false | true | 'ON_WIDGET_LOAD' | 'ON_EXIT_INTENT'

  /**
   * Whether to hide the upload attachment button in the chat widget.
   * @default false
   */
  disableAttachment?: boolean

  /**
   * Whether to automatically prevent focusing on the widget's input field after an inline embedded widget is initially loaded.
   * @default false
   */
  disableInitialInputFocus?: boolean

  /**
   * When set to true, injects a link tag with externally hosted CSS instead of a direct dynamic insertion of a style tag.
   * @default false
   */
  avoidInlineStyles?: boolean

  identificationEmail?: string
  identificationToken?: string
}

declare global {
  interface Window {
    HubSpotConversations: HubSpotConversations
    hsConversationsOnReady: any
    hsConversationsSettings: HsConversationsSettings
  }
}

export function useHubSpotConversations() {
  const load = useCallback(async (options?: { widgetOpen?: boolean }) => {
    window.HubSpotConversations?.widget?.load(options)
    let loaded = window.HubSpotConversations?.widget?.status()?.loaded
    while (!loaded) {
      // eslint-disable-next-line
      await new Promise(resolve => setTimeout(resolve, 100))
      loaded = window.HubSpotConversations?.widget?.status()?.loaded
    }
  }, [])

  const remove = useCallback(() => {
    window.HubSpotConversations?.widget?.remove()
  }, [])

  const status = useCallback(
    () => window.HubSpotConversations?.widget?.status(),
    []
  )

  const open = useCallback(async () => {
    const loaded = window.HubSpotConversations?.widget?.status()?.loaded
    if (!loaded) {
      await load()
    }
    window.HubSpotConversations?.widget?.open()
  }, [load])

  const close = useCallback(() => {
    window.HubSpotConversations?.widget?.close()
  }, [])

  const resetAndReloadWidget = useCallback(() => {
    window.HubSpotConversations?.resetAndReloadWidget()
  }, [])

  return {
    load,
    remove,
    status,
    open,
    close,
    resetAndReloadWidget,
  }
}
