import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormError,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PasswordInput,
  TextInput,
} from '@tabeo/scarf2'
import {
  authEmail,
  AuthLayout,
  AuthLayoutBox,
  ScheduleCall,
} from 'components/layouts/AuthLayout'
import config, { getAppUrlForCountry } from 'config'
import z from 'lib/zod'
import { ReactNode, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

const { COUNTRY } = config

const formSchema = z.object({
  email: authEmail,
  password: z.string().min(1, {
    message: 'This field is required',
  }),
})

type FormValues = z.infer<typeof formSchema>

export default function PasswordLogin() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const email = params.get('email') || ''
  const [loginError, setLoginError] = useState<ReactNode | null>(null)
  const { t } = useTranslation()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onTouched',
    defaultValues: {
      email,
      password: '',
    },
  })
  const { resource } = useAuth()

  async function onSubmit(values: FormValues) {
    try {
      setLoginError(null)
      await resource?.login({
        username: values.email,
        password: values.password,
      })
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        return form.setError('root', {
          message: t('The email/password combination is incorrect'),
        })
      }

      if (error.message.startsWith('invalid_country')) {
        const country = error.message.split(':')?.[1]
        const url = getAppUrlForCountry('merchant', country)
        return setLoginError(
          <span>
            <Trans>
              This account is registered in a different country, please use this
              link to login:
              <br />
              <a className="underline" href={`${url}/login`}>
                {url}
              </a>
            </Trans>
          </span>
        )
      }

      if (error.message === 'invalid_role') {
        const url = getAppUrlForCountry('app', COUNTRY)
        return setLoginError(
          <span>
            <Trans>
              You are registered as a Consumer. Please go to{' '}
              <a className="underline" href={`${url}/login`}>
                {url}
              </a>{' '}
              and log in from there
            </Trans>
          </span>
        )
      }

      if (error.message === 'deleted_user') {
        return setLoginError('This user was deleted, please contact support.')
      }

      form.setError('root', {
        message: 'Something went wrong, please try again later.',
      })
    }
  }

  const formEmail = form.watch('email')
  params.set('email', formEmail)

  return (
    <AuthLayout>
      <AuthLayoutBox>
        <h1 className="title mb-6">Sign in to Merchant App</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <TextInput
                      placeholder="e.g. name@email.co.uk"
                      type="email"
                      onClear={
                        field.value ? () => field.onChange('') : undefined
                      }
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <PasswordInput {...field} />
                  </FormControl>
                  <FormMessage />
                  <div className="pt-1 text-right">
                    <Link
                      to={`/auth/reset-password?email=${encodeURIComponent(
                        email
                      )}`}
                      className="anchor"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </FormItem>
              )}
            />

            <FormError>{loginError}</FormError>
            <Button
              type="submit"
              variant="primary"
              loading={form.formState.isSubmitting}
              className="w-full"
            >
              Sign in
            </Button>
          </form>
        </Form>
        <div className="mt-5 text-center">
          <Link
            to={`/auth/request-otp?${params.toString()}`}
            className="anchor"
          >
            Send me a sign-in code
          </Link>
        </div>
      </AuthLayoutBox>
      <ScheduleCall />
    </AuthLayout>
  )
}
