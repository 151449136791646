import { useTranslation } from 'react-i18next'
import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import { Link, useHistory } from 'react-router-dom'
import { useVendor } from 'resources/Vendor'
import { usePlansV2 } from 'resources/PlansV2'
import {
  Breadcrumb,
  Title,
  GridTable,
  GridTableCell,
  GridTableRow,
  Subheading,
  Settings,
} from '@tabeo/scarf'
import { Button, TextInput, toast } from '@tabeo/scarf2'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import config from 'config'
import { useRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import Notifications from 'components/Notifications'

const { Section, Stack } = Settings

function Row(props: React.ComponentProps<typeof GridTableRow>) {
  return (
    <GridTableRow
      className="[&>div]:border-b [&>div]:last:border-b-0"
      {...props}
    />
  )
}

function CellHead(props: React.ComponentProps<typeof GridTableCell>) {
  return (
    <GridTableCell
      className="!py-1 !text-xs !font-medium !text-tabeo-ink-2"
      {...props}
    />
  )
}

export default function PublicPlansSharing() {
  const { t } = useTranslation()
  const { data: plans } = usePlansV2()
  const { data: vendor } = useVendor()
  const history = useHistory()

  const inputRef = useRef<HTMLInputElement>(null)
  const qrCodeRef = useRef<HTMLCanvasElement>(null)

  const publicUrl = `${config.CONSUMER_APP_URL}/plans/${vendor?.slug}`

  const handleDownload = () => {
    const node = qrCodeRef.current

    if (!node) {
      return
    }

    node.toBlob(blob => {
      if (!blob) {
        return
      }

      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.download = 'QR.png'
      a.href = url
      a.click()
      a.remove()

      URL.revokeObjectURL(url)
    })

    toast.success(t('QR code successfully downloaded'))
  }

  const publicPlans = plans?.items?.filter(p => p.status === 'public') ?? []

  return (
    <LayoutSideNavbar isLoading={!plans || !vendor}>
      {plans && (
        <Container bg="white" maxWidth="812px">
          <Breadcrumb mb={4} onBackClick={history.goBack}>
            <Breadcrumb.Item as={Link} to="/plans">
              {t('Plans')}
            </Breadcrumb.Item>
            <Breadcrumb.Item as={Link} to="/plans/sharing">
              {t('Sharing')}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Notifications mb={4} />
          <Title>{t('Public plans sharing')}</Title>
          <Section className="space-y-1">
            <div className="space-y-0">
              <Section.Title>{t('Public page link')}</Section.Title>
              <Section.Description>
                {t('Share in emails or on your website')}
              </Section.Description>
            </div>
            <div className="flex gap-2">
              <TextInput
                ref={inputRef}
                value={publicUrl}
                readOnly
                className="min-w-fit border-0"
                onClick={async () => {
                  inputRef.current?.select()
                  await navigator.clipboard.writeText(publicUrl)
                  toast.success(t('Link has been copied'))
                }}
                wrapClassName="w-full max-w-sm"
              />
              <a href={publicUrl} target="_blank" rel="noopener noreferrer">
                <Button className="h-10 w-10 p-2" variant="secondary">
                  <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </Button>
              </a>
            </div>
          </Section>
          <Section>
            <div className="flex items-start gap-4">
              <QRCodeCanvas
                ref={qrCodeRef}
                value={`${publicUrl}?utm_source=qr_code`}
                className="aspect-square !h-[95px] !w-[95px]"
                size={328}
              />
              <div className="space-y-1">
                <div className="space-y-0">
                  <Section.Title>{t('QR code')}</Section.Title>
                  <Section.Description>
                    {t(
                      'Share on physical materials like flyers for members to scan'
                    )}
                  </Section.Description>
                </div>
                <Button variant="secondary" onClick={handleDownload}>
                  {t('Download')}
                </Button>
              </div>
            </div>
          </Section>
          <Section>
            <Stack>
              <Stack.Item borderBottom={1}>
                <Subheading>{t('Plans')}</Subheading>
              </Stack.Item>
              <GridTable
                className="overflow-x-auto"
                templateColumns="minmax(140px,auto) minmax(140px, 1fr) minmax(140px, 1fr) minmax(140px, auto)"
              >
                <Row>
                  <CellHead>{t('Plan ID')}</CellHead>
                  <CellHead>{t('Plan name')}</CellHead>
                  <CellHead>{t('Internal reference')}</CellHead>
                  <CellHead>{t('No of members')}</CellHead>
                </Row>
                {publicPlans.length > 0 ? (
                  publicPlans.map(p => (
                    <Row key={p.id}>
                      <GridTableCell>{p.displayId}</GridTableCell>
                      <GridTableCell>{p.name}</GridTableCell>
                      <GridTableCell>{p.internalReference}</GridTableCell>
                      <GridTableCell>
                        {t('{{count}} members', {
                          count: p.membershipCount,
                        })}
                      </GridTableCell>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <GridTableCell className="col-span-4 max-w-[458px] !whitespace-normal">
                      {t(
                        'There are currently no plans to display. Please either set your desired plans to public or create a new one on the plans home page.'
                      )}
                    </GridTableCell>
                  </Row>
                )}
              </GridTable>
            </Stack>
          </Section>
        </Container>
      )}
    </LayoutSideNavbar>
  )
}
