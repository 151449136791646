import { Modal, usePromise } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import React from 'react'
import { useVendor } from 'resources/Vendor'
import { ReactComponent as Illustration } from './illustration.svg'

export type UpdateDetailsModalProps = React.ComponentProps<typeof Modal>

const UpdateDetailsModal = React.forwardRef(
  (props: UpdateDetailsModalProps, ref: any) => {
    const { resource } = useVendor()
    const handleContinue = async () => {
      const url = await resource?.getStripeOnboardingUrl()
      window.open(url, '_blank')
    }

    const [trigger, { pending }] = usePromise(handleContinue)

    return (
      <Modal ref={ref} {...props}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <div className="mx-auto max-w-[90%] space-y-6 rounded bg-white p-5 shadow-md md:max-w-[420px] md:p-10">
              <Illustration className="mx-auto" />
              <div className="text-center">
                <h2 className="title">Business verification</h2>
                <p className="mt-1">
                  You will be redirected to Stripe to complete your
                  verification.
                </p>
              </div>
              <div>
                <Button
                  variant="primary"
                  loading={pending}
                  onClick={trigger}
                  className="mb-4 w-full"
                >
                  Continue
                </Button>
                <Button onClick={close} className="w-full">
                  Close
                </Button>
              </div>
            </div>
          ) : null
        }}
      </Modal>
    )
  }
)

export default UpdateDetailsModal
