import { Modal } from '@tabeo/scarf'
import { Button, FFTextInput } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import { maxLength, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import succeededImg from 'components/terminal/TerminalPayment/assets/succeeded.png'
import { useCardReaders } from 'resources/CardReaders'

export type UpdateReaderModalProps = React.ComponentProps<typeof Modal>

const UpdateReaderModal = React.forwardRef(
  (props: UpdateReaderModalProps, ref: any) => {
    const { t } = useTranslation()
    const { resource, data: cardReaders } = useCardReaders()

    return (
      <Modal ref={ref} {...props}>
        {({ isOpen, close, data: reader }) => {
          async function handleSubmit(values: any) {
            try {
              await resource?.updateReaderById(reader.id, values)
            } catch (e) {
              return formError(e)
            }
          }
          return isOpen ? (
            <div className="mx-auto max-w-[90%] rounded bg-white p-5 shadow-md md:max-w-[540px] md:p-10">
              <Form onSubmit={handleSubmit} initialValues={reader}>
                {({ handleSubmit, submitting, submitSucceeded }) => {
                  return submitSucceeded ? (
                    <div className="text-center">
                      <img
                        src={succeededImg}
                        alt="successimg"
                        className="mx-auto w-[160px]"
                      />
                      <h2 className="title mt-6">{t('Label updated')}</h2>
                      <p className="mt-1">
                        {t(
                          'The update will now be visible wherever this label is used.'
                        )}
                      </p>

                      <Button
                        type="button"
                        onClick={close}
                        className="mt-6 w-full"
                        variant="primary"
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <h2 className="title mb-4">{t('Edit label')}</h2>

                      {/* @ts-ignore */}
                      <Field
                        name="label"
                        label={t('Label')}
                        placeholder={t('E.g. Reception')}
                        component={FFTextInput}
                        validate={[
                          required,
                          maxLength(20),
                          (v: string) => {
                            if (
                              cardReaders?.items?.some(cr => cr.label === v)
                            ) {
                              return t(
                                '{{label}} is already in use. Please enter a unique label.',
                                {
                                  label: v,
                                }
                              )
                            }
                            return undefined
                          },
                        ]}
                        description={t(
                          'This helps the team identify the reader'
                        )}
                        clearable
                      />

                      <div className="mt-6">
                        <Button
                          variant="primary"
                          loading={submitting}
                          className="mb-4 w-full"
                          type="submit"
                        >
                          {t('Confirm label')}
                        </Button>
                        <Button onClick={close} className="w-full">
                          {t('Close')}
                        </Button>
                      </div>
                      <FormError />
                    </form>
                  )
                }}
              </Form>
            </div>
          ) : null
        }}
      </Modal>
    )
  }
)

export default UpdateReaderModal
