import { Tag } from '@tabeo/scarf2'
import { t } from 'i18n'

type TagProps = React.ComponentPropsWithoutRef<typeof Tag>

const states = {
  public: {
    color: 'green',
    label: t('Public'),
  },
  private: {
    color: 'blue',
    label: t('Private'),
  },
  closed: {
    color: 'red',
    label: t('Closed'),
  },
} as const

export type PlanV2StateTagProps = TagProps & { status?: string }

export default function PlanV2StateTag({
  status,
  ...rest
}: PlanV2StateTagProps) {
  const currentState = status
    ? states[status as keyof typeof states]
    : undefined
  const color = currentState?.color || 'gray'
  const label = currentState?.label || status

  return (
    <Tag color={color} {...rest}>
      {label}
    </Tag>
  )
}
