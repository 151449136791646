import { Button, Caption, Title, usePromise, View } from '@tabeo/scarf'
import { toast } from '@tabeo/scarf2'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'
import dinoReaderImg from '../assets/dino-reader.png'
import TerminalPaymentContext from '../Context'

function ReaderHandoffError() {
  const { t } = useTranslation()
  const { dispatch, state, modal } = useContext(TerminalPaymentContext)
  const { resource } = useTerminalPayment({
    id: state.terminalPaymentId,
  })

  const [triggerResend, { pending: resendPending }] = usePromise(
    async () => {
      if (resource && state.preferredReaderId && state.isServerDrivenFlow) {
        const saga = await resource.resendPaymentToReader(
          {
            readerId: state.preferredReaderId,
          },
          {
            whilePending: (saga: any) => saga,
          }
        )
        const url = new URL(window.location.href)
        url.searchParams.set('sagaUUID', saga.uuid)
        window.history.replaceState({}, '', url.toString())
      }
      dispatch({ type: 'reload-reader' })
    },
    {
      onError: () => {
        toast.error(t('Failed to resend payment to reader'))
      },
    }
  )

  async function handleCancel() {
    if (state.isServerDrivenFlow) {
      await resource?.cancel()
    }
    if (modal?.isOpen) {
      modal.close()
    }
  }

  const [triggerCancel, { pending: cancelPending }] = usePromise(handleCancel)

  return (
    <View textAlign="center">
      <View as="img" src={dinoReaderImg} width="176px" mx="auto" />
      <Title mt={6}>{t('Your reader is offline')}</Title>
      <Caption mt={1}>
        {t('Check it’s connected and has power before trying again.')}
      </Caption>
      <View mt={6}>
        <Button onClick={triggerResend} loading={resendPending} width="100%">
          {t('Try again')}
        </Button>
      </View>
      <View mt={4}>
        <Button
          variant="transparent"
          onClick={triggerCancel}
          loading={cancelPending}
        >
          {t('Cancel')}
        </Button>
      </View>
    </View>
  )
}

export default ReaderHandoffError
