import Nav from 'components/Nav'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'
import sanitizeRedirectParam from 'utils/sanitizeRedirectParam'
import { z } from 'zod'

export function AuthLayout({ children }: { children: React.ReactNode }) {
  const {
    data: { token },
  } = useAuth()
  const location = useLocation()
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  const redirect = sanitizeRedirectParam(params.get('redirect')) || '/'
  const history = useHistory()

  // If the user is already logged in, redirect to the redirect URL
  useEffect(() => {
    if (token) {
      history.push(redirect)
    }
  }, [token, history, redirect])

  return (
    <div className="flex flex-col from-tabeo-primary-6 to-tabeo-sky-3 desktop:min-h-screen desktop:bg-gradient-to-br">
      <Nav />
      <div className="flex-1 px-4 pb-8 pt-16 desktop:px-0 desktop:pb-16 desktop:pt-32">
        {children}
      </div>
    </div>
  )
}

export function AuthLayoutBox({ children }: { children: React.ReactNode }) {
  return (
    <div className="mx-auto w-full rounded bg-white px-3 py-5 desktop:max-w-[504px] desktop:border desktop:border-tabeo-sky-1 desktop:px-12 desktop:py-12 desktop:shadow-tabeo-md">
      {children}
    </div>
  )
}

export function ScheduleCall() {
  return (
    <p className="mx-auto mt-5 w-full pl-3 text-tabeo-ink-1 desktop:max-w-[504px]  desktop:pl-2">
      Not registered?{' '}
      <a
        href="https://calendly.com/tabeo_introduction/sales-login-callback"
        className="anchor"
        target="_blank"
        rel="noreferrer"
      >
        Schedule a call with Tabeo
      </a>
    </p>
  )
}

export const authEmail = z
  .string()
  .min(1, 'This field is required')
  .regex(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Please enter a valid email address'
  )
