import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid'
import { BoltSlashIcon } from '@heroicons/react/24/outline'
import {
  Button,
  Notification,
  RadioCards,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { FormApi } from 'final-form'
import useStripeTerminal from 'hooks/useStripeTerminal'
import { CommonReader } from 'pages/NNTS/types'
import { SVGProps, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCardReaders } from 'resources/CardReaders'
import { useMerchant } from 'resources/Merchant'
import { twMerge } from 'tailwind-merge'
import { useNewTransactionFlow } from '../provider'
import CardLogos from './CardLogos'

export function TerminalPaymentOption({ disabled }: { disabled?: boolean }) {
  const { state } = useNewTransactionFlow()
  const { t } = useTranslation()
  const { data } = useMerchant()
  const isAmexCapable = data?.activeFeeGroup?.isAmexCapable

  return (
    <RadioCards.Option value="terminal" disabled={disabled}>
      <div className="flex items-start gap-x-2">
        <RadioCards.OptionDot />

        <div className="flex flex-1 items-start justify-between">
          <div className="space-y-2">
            <div className="font-medium">{t('Terminal')}</div>
            <CardLogos amex={isAmexCapable} />
          </div>
          <div className="flex flex-col items-end gap-2">
            {format(state.payment?.price)}
          </div>
        </div>
      </div>
    </RadioCards.Option>
  )
}

export function TerminalActions() {
  const { t } = useTranslation()
  const { form, flowSteps, currentIndex } = useNewTransactionFlow()
  const { submitting, values } = form.current?.getState() || {}
  const [readers, setReaders] = useState<CommonReader[]>([])

  const formRef = form.current
  const selectedReader = values?.reader
  const hasNoReaders = readers.length === 0
  const buttonDisabled =
    (!!selectedReader && selectedReader?.status !== 'online') || hasNoReaders

  const isReaderBusy = selectedReader?.is_busy

  if (!formRef) {
    return null
  }

  const { isLast } = flowSteps[currentIndex]
  if (!isLast && !isReaderBusy) {
    return (
      <div className="space-y-4">
        <ReaderSelector
          formRef={formRef}
          readers={readers}
          setReaders={setReaders}
        />
        <Button
          variant="primary"
          className="w-full"
          type="submit"
          onClick={form.current?.submit}
          loading={submitting}
          disabled={buttonDisabled}
        >
          {t('Continue')}
        </Button>
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <ReaderSelector
        formRef={formRef}
        readers={readers}
        setReaders={setReaders}
      />
      <Button
        variant="primary"
        className="w-full"
        type="submit"
        onClick={form.current?.submit}
        loading={submitting}
        disabled={buttonDisabled}
      >
        {isReaderBusy ? t('Force connect reader') : t('Send to reader')}
      </Button>
    </div>
  )
}

function StatusIndicator({
  variant,
  ...props
}: { variant: 'green' | 'yellow' | 'gray' } & SVGProps<SVGSVGElement>) {
  const className = {
    green: {
      outer: 'text-tabeo-green-3',
      inner: 'text-tabeo-green-5',
    },
    yellow: {
      outer: 'text-tabeo-yellow-3',
      inner: 'text-tabeo-yellow-5',
    },
    gray: {
      outer: 'text-tabeo-ink-3',
      inner: 'text-tabeo-sky-2',
    },
  }
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2203_4253)">
        <g filter="url(#filter0_f_2203_4253)">
          <circle
            cx={9}
            cy={9}
            r={6}
            fill="currentColor"
            className={className[variant].outer}
          />
        </g>
        <circle
          cx={9}
          cy={9}
          r={3}
          fill="currentColor"
          className={className[variant].inner}
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2203_4253"
          x={0}
          y={0}
          width={18}
          height={18}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={1.5}
            result="effect1_foregroundBlur_2203_4253"
          />
        </filter>
        <clipPath id="clip0_2203_4253">
          <path fill="#fff" d="M0 0H18V18H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

function ReaderListItem({ reader }: { reader: CommonReader }) {
  const isOnline = reader.status === 'online'
  const isBusy = reader.is_busy
  let indicatorVariant: 'green' | 'yellow' | 'gray' = 'gray'
  if (isOnline) {
    indicatorVariant = 'green'
  }
  if (isBusy) {
    indicatorVariant = 'yellow'
  }
  return (
    <SelectItem
      value={reader.id}
      disabled={!isOnline}
      className="min-w-[280px] px-3 py-3 data-[disabled]:opacity-100"
      hideIndicator
    >
      <div className="flex items-start gap-1">
        <StatusIndicator variant={indicatorVariant} className="my-[3px]" />
        <div className="flex-1">
          <div
            className={twMerge(
              'text-base font-medium',
              isOnline ? 'text-tabeo-ink-0' : 'text-tabeo-sky-0'
            )}
          >
            {reader.label}
          </div>
          {!isOnline && (
            <div className="text-sm text-tabeo-ink-2">
              Check connection/power
            </div>
          )}
          {isBusy && (
            <div className="text-sm text-tabeo-ink-2">
              Reader currently in use
            </div>
          )}
        </div>
      </div>
    </SelectItem>
  )
}

function ReaderSelector({
  formRef,
  readers,
  setReaders,
}: {
  formRef: FormApi<any, any>
  readers: CommonReader[]
  setReaders: (readers: CommonReader[]) => void
}) {
  const { discoverReaders } = useStripeTerminal()
  const { values, submitFailed, errors } = formRef.getState()
  const selectedReader = values?.reader
  const readerError = errors?.reader
  const invalid = !!(submitFailed && readerError === 'empty')
  const { resource } = useCardReaders(
    {},
    {
      isEnabled: false,
    }
  )
  const { data: merchant } = useMerchant()

  const isServerDrivenFlow =
    merchant?.flags?.is_server_driven_reader_connection_capable

  useEffect(() => {
    async function discover() {
      let readers: CommonReader[] = []
      if (isServerDrivenFlow) {
        const response = await resource?.fetch()
        readers = response?.items || []
      } else {
        readers = await discoverReaders()
      }
      setReaders(readers)
      const {
        values: { reader: selectedReader },
      } = formRef.getState()
      if (selectedReader?.id) {
        const reader = readers.find(r => r.id === selectedReader.id) || null
        formRef.change('reader', reader)
      }

      if (readers.length === 1) {
        formRef.change('reader', readers[0])
      }
    }

    const timer = setInterval(() => {
      discover()
    }, 5000)

    // Discover readers immediately
    discover()

    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (readers.length === 1 && readers[0].status === 'offline') {
    return (
      <Notification variant="default">
        <BoltSlashIcon className="h-5 w-5 self-start" />
        Reader offline. Check it’s connected and has power before continuing.
      </Notification>
    )
  }

  if (readers.length === 1 && readers[0].is_busy) {
    return (
      <Notification variant="warning">
        <ExclamationTriangleIcon className="h-5 w-5 self-start" />
        Reader currently in use. Continuing may interrupt a payment in progress.
      </Notification>
    )
  }

  if (readers.length < 2) {
    return null
  }

  return (
    <div className="space-y-1">
      <p className="subheading text-tabeo-ink-1">Reader</p>
      <Select
        value={selectedReader?.id}
        onValueChange={id => {
          const reader = readers.find(r => r.id === id)
          if (reader) {
            formRef.change('reader', reader)
          }
        }}
      >
        <SelectTrigger
          invalid={!!invalid}
          touched={!!invalid}
          className="bg-white py-[7px] focus:bg-white [&>span>div>div>div:nth-child(2)]:hidden"
        >
          <SelectValue placeholder="Select a reader ..." />
        </SelectTrigger>
        <SelectContent viewportClassName="p-0" side="top" align="end">
          {readers
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(reader =>
              reader.status === 'offline' ? (
                <div className="cursor-not-allowed" key={reader.id}>
                  <ReaderListItem reader={reader} />
                </div>
              ) : (
                <ReaderListItem key={reader.id} reader={reader} />
              )
            )}
        </SelectContent>
      </Select>
      {submitFailed && readerError === 'empty' && (
        <div className="flex gap-1 text-sm text-tabeo-red-3">
          <ExclamationCircleIcon className="h-4 w-4" />A reader must be selected
        </div>
      )}
      {selectedReader?.status === 'offline' && (
        <div className="text-sm text-tabeo-ink-2">
          Reader offline. Check it’s connected and has power before continuing.
        </div>
      )}
      {selectedReader?.is_busy && (
        <div className="text-sm text-tabeo-ink-2">
          Reader currently in use. Continuing may interrupt a payment in
          progress.
        </div>
      )}
    </div>
  )
}
