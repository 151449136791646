import { Button, Modal, usePromise } from '@tabeo/scarf'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as PlaneYellow } from 'components/checkout-payment/assets/plane-yellow.svg'
import StripeTerminalProvider from 'components/terminal/StripeTerminalProvider'
import TerminalPaymentModal from 'components/terminal/TerminalPayment/Modal'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTerminalPayment } from 'resources/TerminalPayment'

function Created() {
  const { t } = useTranslation()

  const { data, resource } = useTerminalPayment()

  const [triggerCancel, cancelState] = usePromise(
    resource?.cancel || (() => {})
  )

  const modal = useRef<Modal>()

  return (
    <StripeTerminalProvider>
      <>
        <TerminalPaymentModal ref={modal} />
        <ActionCard>
          <ActionCard.Header>
            <PlaneYellow className="h-6 w-6" />
            {t('Created')}
          </ActionCard.Header>
          <ActionCard.Body>
            {t('This payment is ready to be loaded onto the terminal.')}
          </ActionCard.Body>
          <ActionCard.Actions>
            <Button
              sizeVariant="sm"
              onClick={() => {
                modal.current?.open({
                  id: data?.id,
                })
              }}
            >
              {t('Send to reader')}
            </Button>
            <Button
              sizeVariant="sm"
              variant="secondary"
              onClick={triggerCancel}
              loading={cancelState.pending}
            >
              {t('Cancel')}
            </Button>
          </ActionCard.Actions>
          {cancelState.error && (
            <ActionCard.Error>
              {t('There was an error, please try again later.')}
            </ActionCard.Error>
          )}
        </ActionCard>
      </>
    </StripeTerminalProvider>
  )
}

export default Created
