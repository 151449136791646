import { SparklesIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormError,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TextInput,
} from '@tabeo/scarf2'
import {
  authEmail,
  AuthLayout,
  AuthLayoutBox,
  ScheduleCall,
} from 'components/layouts/AuthLayout'
import z from 'lib/zod'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAuth } from 'resources/Auth'

const formSchema = z.object({
  email: authEmail,
})

type FormValues = z.infer<typeof formSchema>

export default function RequestOTP() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const email = params.get('email') || ''

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: 'onTouched',
    defaultValues: {
      email,
    },
  })
  const { resource } = useAuth()
  const history = useHistory()

  async function onSubmit(values: FormValues) {
    try {
      await resource?.requestOTP(values)
      params.set('email', values.email)
      params.delete('otp')
      history.push(`/auth/enter-otp?${params.toString()}`)
    } catch (error: any) {
      if (
        error?.message === 'magic link already requested' ||
        error?.response?.data?.data?.email
      ) {
        return form.setError('email', {
          message:
            'Your login code has already been sent. You can request a new one in 30 seconds.',
        })
      }

      form.setError('root', {
        message: 'Something went wrong, please try again later.',
      })
    }
  }

  const formEmail = form.watch('email')
  params.set('email', formEmail)

  return (
    <AuthLayout>
      <AuthLayoutBox>
        <h1 className="title mb-6">Sign in to Merchant App</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <TextInput
                      placeholder="e.g. name@email.co.uk"
                      type="email"
                      onClear={
                        field.value ? () => field.onChange('') : undefined
                      }
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormError />
            <div>
              <Button
                type="submit"
                variant="primary"
                loading={form.formState.isSubmitting}
                className="w-full"
              >
                Send me a code
              </Button>
              <div className="mt-3 flex items-start gap-2">
                <SparklesIcon className="h-5 w-5 shrink-0 text-[#919EAB]" />
                <p className="caption text-tabeo-ink-2">
                  We&apos;ll email you a one-time code for a password-free
                  sign-in.
                </p>
              </div>
            </div>
          </form>
        </Form>
        <div className="mt-5 flex items-center gap-2">
          <div className="h-[1px] flex-1 bg-tabeo-sky-1" />
          <span className="subheading text-tabeo-ink-2">OR</span>
          <div className="h-[1px] flex-1 bg-tabeo-sky-1" />
        </div>
        <Button
          variant="secondary"
          className="mt-5 w-full"
          as={Link}
          to={`/auth/password-login?${params.toString()}`}
        >
          Sign in with password
        </Button>
      </AuthLayoutBox>
      <ScheduleCall />
    </AuthLayout>
  )
}
