import { Modal } from '@tabeo/scarf'
import React from 'react'
import TerminalPayment from '.'

const TerminalPaymentModal = React.forwardRef<any, any>(
  ({ onSuccess, ...rest }, ref) => {
    return (
      <Modal ref={ref} closable={false} {...rest}>
        {({ isOpen, close, data }) => {
          return isOpen ? (
            <TerminalPayment
              id={data.id}
              reader={data.reader}
              modal={{ close, isOpen }}
              onSuccess={onSuccess}
            />
          ) : null
        }}
      </Modal>
    )
  }
)

export default TerminalPaymentModal
