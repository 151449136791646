import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  toast,
} from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import { QRCodeCanvas } from 'qrcode.react'
import { useRef } from 'react'
import { plansorchestrator } from '@tabeo/platform-api-client'

type ViewQrCodeDialogProps = {
  children: React.ReactNode
  url: string
  data?: plansorchestrator.ModelsPlan
}

function ViewQrCodeDialog({ children, url, data }: ViewQrCodeDialogProps) {
  const ref = useRef<HTMLCanvasElement | null>(null)
  const { t } = useTranslation()

  const handleDownload = () => {
    const node = ref.current

    if (!node) {
      return
    }

    node.toBlob(blob => {
      if (!blob) {
        return
      }

      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.download = `${data?.displayId}-QR.png`
      a.href = url
      a.click()
      a.remove()

      URL.revokeObjectURL(url)
    })

    toast.success(t('QR code successfully downloaded'))
  }

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent size="sm">
        <DialogHeader className="title">QR code</DialogHeader>
        <DialogBody className="space-y-4">
          <div className="flex gap-5">
            <QRCodeCanvas
              ref={ref}
              value={`${url}?utm_source=qr_code`}
              className="mx-auto aspect-square !h-[110px] !w-[110px]"
              size={328}
            />
            <div className="grid gap-1">
              <div className="caption font-medium">
                {t('Easily share plans with patients')}
              </div>
              <div className="caption text-tabeo-ink-2">
                {t(
                  'Download this QR code and add it to your marketing materials or social media to make it easy for patients to scan and sign up.'
                )}
              </div>
            </div>
          </div>
          <div className="caption text-tabeo-ink-2">
            {t('Image will be downloaded as a PNG file.')}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button variant="primary" onClick={handleDownload}>
            Download QR code
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ViewQrCodeDialog
