import { Modal } from '@tabeo/scarf'
import { Button, FFTextInput } from '@tabeo/scarf2'
import formError from '@tabeo/sharpei/utils/formError'
import { maxLength, required } from '@tabeo/sharpei/utils/validations'
import Field from 'components/Form/Field'
import FormError from 'components/Form/FormError'
import React from 'react'
import { Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

import succeededImg from 'components/terminal/TerminalPayment/assets/succeeded.png'
import { Link } from 'react-router-dom'
import { useCardReaders } from 'resources/CardReaders'

export type CreateReaderModalProps = React.ComponentProps<typeof Modal>

// This function should make sure the value has the format of "word-word-word"
// and that each word should only contain letters
// NOTE: Currently not used as per QA's request
export const registrationCodeValidator = (value = '') => {
  const errorMessage = 'Invalid registration code'

  const words = value.split('-')

  // Check the format
  if (words.length !== 3) {
    return errorMessage
  }

  // Check if any word has non letter characters
  if (words.some(word => !/^[a-zA-Z]+$/.test(word))) {
    return errorMessage
  }

  return undefined
}

const CreateReaderModal = React.forwardRef(
  (props: CreateReaderModalProps, ref: any) => {
    const { t } = useTranslation()
    const { resource, data: cardReaders } = useCardReaders()

    async function handleSubmit(values: any) {
      try {
        const { status } = await resource?.create(values)
        if (status === 'failed_invalid_registration_code') {
          return {
            registrationCode: t('The registration code is incorrect.'),
          }
        }
      } catch (e) {
        return formError(e)
      }
    }

    return (
      <Modal ref={ref} {...props}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <div className="mx-auto max-w-[90%] rounded bg-white p-5 shadow-md md:max-w-[540px] md:p-10">
              <Form onSubmit={handleSubmit}>
                {({ handleSubmit, submitting, submitSucceeded }) => {
                  return submitSucceeded ? (
                    <div className="text-center">
                      <img src={succeededImg} className="mx-auto w-[160px]" />
                      <h2 className="title mt-6">
                        {t('Reader successfully registered')}
                      </h2>
                      <p className="mt-1">
                        {t(
                          'The reader may need to download some updates to become fully operational. This should only take a few minutes.'
                        )}
                      </p>
                      <Button
                        type="button"
                        onClick={close}
                        className="mt-6 w-full"
                        variant="primary"
                        as={Link}
                        to="/new-transaction"
                      >
                        {t('Create new transaction')}
                      </Button>
                      <Button
                        type="button"
                        onClick={close}
                        className="mt-4 w-full"
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <h2 className="title mb-3">{t('Register reader')}</h2>

                      <p className="mb-4">
                        <Trans>
                          To set up your reader and receive the registration
                          code, follow the steps in the{' '}
                          <a
                            href="https://intercom.help/tabeo/en/articles/6913358-how-to-set-up-your-card-reader"
                            className="anchor"
                            target="_blank"
                            rel="noreferrer"
                          >
                            quick setup guide
                          </a>
                          .
                        </Trans>
                      </p>

                      {/* @ts-ignore */}
                      <Field
                        name="registrationCode"
                        label={t('Registration code')}
                        placeholder={t('E.g. apple-grape-orange')}
                        component={FFTextInput}
                        validate={[required]}
                      />

                      {/* @ts-ignore */}
                      <Field
                        name="label"
                        label={t('Label')}
                        placeholder={t('E.g. Reception')}
                        component={FFTextInput}
                        validate={[
                          required,
                          maxLength(20),
                          (v: string) => {
                            if (
                              cardReaders?.items?.some(cr => cr.label === v)
                            ) {
                              return t(
                                '{{label}} is already in use. Please enter a unique label.',
                                {
                                  label: v,
                                }
                              )
                            }
                            return undefined
                          },
                        ]}
                        description={t(
                          'This helps the team identify the reader'
                        )}
                      />

                      <div className="mt-6">
                        <Button
                          variant="primary"
                          loading={submitting}
                          className="mb-4 w-full"
                          type="submit"
                        >
                          {t('Confirm')}
                        </Button>
                        <Button onClick={close} className="w-full">
                          {t('Close')}
                        </Button>
                      </div>

                      <FormError />
                    </form>
                  )
                }}
              </Form>
            </div>
          ) : null
        }}
      </Modal>
    )
  }
)

export default CreateReaderModal
