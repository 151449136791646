import { GridTableCell as Cell, GridTableRow, themeGet } from '@tabeo/scarf'
import ScarfProps from '@tabeo/scarf/dist/types/ScarfProps'
import { format } from '@tabeo/sharpei/utils/currency'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { plansorchestrator } from '@tabeo/platform-api-client'
import { useTranslation } from 'react-i18next'
import PlanV2StateTag from 'components/PlanV2StateTag'

const Row = styled(GridTableRow)<ScarfProps>`
  & > * {
    border-top: 1px solid ${themeGet('colors.sky.1')};
  }
  &:hover > * {
    background: ${themeGet('colors.sky.3', 'white')};
  }
`

export type ListItemProps = {
  data: plansorchestrator.ModelsSelectPlansAggregate
}

const ListItem = ({ data }: ListItemProps) => {
  const { t } = useTranslation()

  return (
    <Row bg="white" as={Link} to={`/plans/${data.id}`}>
      <Cell color="ink.2">{data.displayId}</Cell>
      <Cell>{data.name}</Cell>
      <Cell>{data.internalReference}</Cell>
      <Cell color="ink.2">
        {t('{{count}} members', {
          count: data.membershipCount,
        })}
      </Cell>
      <Cell textAlign="right" color="ink.2">
        {t('{{amount}} / month', { amount: format(data.price) })}
      </Cell>
      <Cell textAlign="right" color="ink.2">
        <PlanV2StateTag size="sm" status={data.status} />
      </Cell>
    </Row>
  )
}

export default ListItem
