import { twMerge } from 'tailwind-merge'
import gmail from './assets/gmail.png'
import outlook from './assets/outlook.png'
import yahoo from './assets/yahoo.png'

const emailClients = [
  {
    name: 'gmail',
    cta: 'Open Gmail',
    icon: gmail,
    url: 'https://mail.google.com/mail/u/0/#inbox',
  },
  {
    name: 'outlook',
    cta: 'Open Outlook',
    icon: outlook,
    url: 'https://outlook.office.com/mail/',
  },
  {
    name: 'yahoo',
    cta: 'Open Yahoo',
    icon: yahoo,
    url: 'https://mail.yahoo.com/',
  },
]

export const EmailClients = ({ className }: { className?: string }) => {
  return (
    <div
      className={twMerge(
        'flex flex-wrap items-center gap-x-5 gap-y-3',
        className
      )}
    >
      {emailClients.map(client => (
        <a
          key={client.name}
          href={client.url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-[2px]"
        >
          <img src={client.icon} alt={client.name} className="h-6 w-6" />
          <span className="caption whitespace-nowrap text-tabeo-ink-2 underline hover:text-tabeo-ink-0">
            {client.cta}
          </span>
        </a>
      ))}
    </div>
  )
}
